import { Box, Typography, CircularProgress } from '@mui/material';
import {
  ResponsiveContainer,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Bar,
  ComposedChart,
} from 'recharts';
import { isEmpty } from 'lodash';
import { format, isDate } from 'date-fns';
import { useCreditLimitUsages } from 'hooks/useCreditLimitUsages';
import { Protocol } from 'types';

interface CreditUsageChartProps {
  network: Protocol;
  account: string;
  market: string;
}

const getTickValue = (value: number) =>
  Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 1,
  }).format(value);

export const CreditUsageChart = ({
  network,
  account,
  market,
}: CreditUsageChartProps) => {
  const { creditUsages, isLoading } = useCreditLimitUsages(network, account, [
    market,
  ]);
  const data = !isEmpty(creditUsages) ? creditUsages[0] : undefined;
  return (
    <Box>
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : data ? (
        <ResponsiveContainer height={200}>
          <ComposedChart data={data.data}>
            <CartesianGrid strokeDasharray="3 3" />
            <YAxis
              yAxisId="usage"
              unit="%"
              domain={[0, 100]}
              orientation="left"
            />
            <YAxis
              yAxisId="balance"
              orientation="right"
              tickFormatter={(value) => getTickValue(value)}
            />
            <Tooltip
              labelFormatter={(label) =>
                isDate(label) ? format(label, 'yyyy/MM/dd HH:mm a') : label
              }
            />
            <Legend />
            <XAxis
              dataKey="date"
              tickFormatter={(date: Date) =>
                isDate(date) ? format(date, 'MM/dd') : ''
              }
            />
            <Bar
              yAxisId="balance"
              dataKey="creditLimit"
              name="Credit Limit"
              fill="#ff9800"
              fillOpacity={0.9}
            />
            <Bar
              yAxisId="balance"
              dataKey="borrowBalanceStored"
              name="Borrow Balance"
              fill="#607d8b"
              fillOpacity={0.9}
            />
            <Line
              yAxisId="usage"
              dataKey="usage"
              strokeWidth={2}
              name="Usage"
            />
          </ComposedChart>
        </ResponsiveContainer>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant="caption">No usage data</Typography>
        </Box>
      )}
    </Box>
  );
};

export default CreditUsageChart;
