import { Drawer, Button, DrawerProps, Stack } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { styled } from '@mui/system';
import useUser from 'hooks/useUser';
import UserCard from 'components/UserCard';

const StyledNavLink = styled(NavLink)({
  textDecoration: 'none',
});

const StyledButton = styled(Button)({
  justifyContent: 'start',
});

function SideMenu(props: DrawerProps): JSX.Element {
  const { user } = useUser();
  return (
    <Drawer variant="permanent" anchor="left" {...props}>
      <Stack gap={2}>
        {user && <UserCard />}
        <StyledNavLink to="/">
          <StyledButton fullWidth>Home</StyledButton>
        </StyledNavLink>
        <StyledNavLink to="markets/ethereum">
          <StyledButton fullWidth>Ethereum</StyledButton>
        </StyledNavLink>
        <StyledNavLink to="markets/optimism">
          <StyledButton fullWidth>Optimism</StyledButton>
        </StyledNavLink>
        <StyledNavLink to="markets/avalanche">
          <StyledButton fullWidth>Avalanche</StyledButton>
        </StyledNavLink>
        <StyledNavLink to="markets/fantom">
          <StyledButton fullWidth>Fantom</StyledButton>
        </StyledNavLink>
        <StyledNavLink to="creditlimits">
          <StyledButton fullWidth>Credit Limits</StyledButton>
        </StyledNavLink>
        <StyledNavLink to="balances">
          <StyledButton fullWidth>Balances</StyledButton>
        </StyledNavLink>
        <StyledNavLink to="addresses">
          <StyledButton fullWidth>Address Management</StyledButton>
        </StyledNavLink>
        <StyledNavLink to="emergency">
          <StyledButton fullWidth>Emergency</StyledButton>
        </StyledNavLink>
        <StyledNavLink to="alphahomora">
          <StyledButton fullWidth>Alpha Homora</StyledButton>
        </StyledNavLink>
        <StyledNavLink to="ibff">
          <StyledButton fullWidth>IBFF Borrower</StyledButton>
        </StyledNavLink>
        <StyledNavLink to="auth">
          <StyledButton fullWidth>User</StyledButton>
        </StyledNavLink>
      </Stack>
    </Drawer>
  );
}

export default SideMenu;
