import { Tooltip, IconButton, Link, Stack } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import useAddress from 'hooks/useAddress';
import { getFormattedAddress, getShortAddress, getBrowserLink } from 'helpers';
import { styled } from '@mui/system';
import { Protocol } from 'types';

const StyledIcon = styled(ContentCopyIcon)({
  fontSize: '1rem',
});

interface AddressDisplayProps {
  address: string;
  network: Protocol;
  isShowName?: boolean;
}

export const AddressDisplay = (props: AddressDisplayProps) => {
  const { address, network, isShowName = true } = props;
  const { addressAlias } = useAddress();

  const alias = getFormattedAddress(network, address, addressAlias);

  const handleCopy = async () => {
    await navigator.clipboard.writeText(alias.address);
    alert('address copied!');
  };
  return (
    <Stack direction="row" alignContent="center" spacing={1}>
      <Tooltip title={alias.address}>
        <Link
          href={getBrowserLink(alias.address, network)}
          target="_blank"
          rel="noreferrer"
        >
          {alias.name && isShowName
            ? alias.name
            : getShortAddress(alias.address)}
        </Link>
      </Tooltip>
      <Tooltip placement="right" title="copy address">
        <IconButton size="small" onClick={handleCopy}>
          <StyledIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export default AddressDisplay;
