import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import SideMenu from 'components/SideMenu';

const drawerWidth = 200;

const Layout = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <SideMenu
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
      />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
