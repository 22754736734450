import { Typography, Grid, Card, Box } from '@mui/material';
import { BalanceCard } from 'components/BalanceCard';
import { SearchBalanceCard } from 'components/SearchBalanceCard';
import { map } from 'lodash';
import { Protocol } from 'types';

const BalancePage = (): JSX.Element => {
  const pinnedCards: Array<{ borrower: string; network: Protocol }> = [
    {
      borrower: '0xcDDBA405f8129e5bAe101045aa45aCa11C03b1c8',
      network: Protocol.Ethereum,
    },
    {
      borrower: '0x085682716f61a72bf8C573FBaF88CCA68c60E99B',
      network: Protocol.Ethereum,
    },
    {
      borrower: '0x560A8E3B79d23b0A525E15C6F3486c6A293DDAd2',
      network: Protocol.Ethereum,
    },
  ];
  return (
    <section>
      <Typography variant="h3">Balances</Typography>
      <Grid container spacing={1}>
        {map(pinnedCards, ({ borrower, network }) => (
          <Grid key={borrower + network} item xs={12} sm={6}>
            <Card>
              <BalanceCard borrower={borrower} network={network} />
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ my: 1 }}>
        <SearchBalanceCard />
      </Box>
    </section>
  );
};

export default BalancePage;
