import CreditLimits from 'components/CreditLimits';
import { TabPanel } from 'components/TabPanel';
import { Protocol } from 'types';
import useCreditLimits from 'hooks/useCreditLimits';
import useTabs from 'hooks/useTab';
import { Box, Tabs, Tab, Stack } from '@mui/material';

function CreditLimitPage() {
  const ethereumCreditData = useCreditLimits(Protocol.Ethereum);
  const fantomCreditData = useCreditLimits(Protocol.Fantom);
  const avalancheCreditData = useCreditLimits(Protocol.Avalanche);
  const optimismCreditData = useCreditLimits(Protocol.Optimism);
  const { tab, handleChange, getA11yProps } = useTabs();

  return (
    <section>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tab} onChange={handleChange} aria-label="tabs">
          <Tab label="Ethereum" {...getA11yProps(0)} />
          <Tab label="Fantom" {...getA11yProps(1)} />
          <Tab label="Avalanche" {...getA11yProps(2)} />
          <Tab label="Optimism" {...getA11yProps(3)} />
        </Tabs>
      </Box>
      <TabPanel value={tab} index={0}>
        <Stack direction="column" gap={1}>
          <CreditLimits
            title="Credit Limits"
            protocol={Protocol.Ethereum}
            creditLimits={ethereumCreditData.creditLimits}
            isLoading={ethereumCreditData.isFetching}
            isShowHistory={true}
          />
          <CreditLimits
            title="Ex-Credit Limits"
            protocol={Protocol.Ethereum}
            creditLimits={ethereumCreditData.expiredCreditLimits}
            isLoading={ethereumCreditData.isFetching}
          />
        </Stack>
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <Stack direction="column" gap={1}>
          <CreditLimits
            title="Credit Limits"
            protocol={Protocol.Fantom}
            creditLimits={fantomCreditData.creditLimits}
            isLoading={fantomCreditData.isFetching}
            isShowHistory={true}
          />
          <CreditLimits
            title="Ex-Credit Limits"
            protocol={Protocol.Fantom}
            creditLimits={fantomCreditData.expiredCreditLimits}
            isLoading={fantomCreditData.isFetching}
          />
        </Stack>
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <Stack direction="column" gap={1}>
          <CreditLimits
            title="Credit Limits"
            protocol={Protocol.Avalanche}
            creditLimits={avalancheCreditData.creditLimits}
            isLoading={avalancheCreditData.isFetching}
            isShowHistory={true}
          />
          <CreditLimits
            title="Ex-Credit Limits"
            protocol={Protocol.Avalanche}
            creditLimits={avalancheCreditData.expiredCreditLimits}
            isLoading={avalancheCreditData.isFetching}
          />
        </Stack>
      </TabPanel>
      <TabPanel value={tab} index={3}>
        <Stack direction="column" gap={1}>
          <CreditLimits
            title="Credit Limits"
            protocol={Protocol.Optimism}
            creditLimits={optimismCreditData.creditLimits}
            isLoading={optimismCreditData.isFetching}
            isShowHistory={true}
          />
          <CreditLimits
            title="Ex-Credit Limits"
            protocol={Protocol.Optimism}
            creditLimits={optimismCreditData.expiredCreditLimits}
            isLoading={optimismCreditData.isFetching}
          />
        </Stack>
      </TabPanel>
    </section>
  );
}

export default CreditLimitPage;
