import { useMemo } from 'react';
import { Web3OnboardProvider, init } from '@web3-onboard/react';
import injectedModule from '@web3-onboard/injected-wallets';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import useAutoOnboard from 'hooks/useAutoOnboard';
import Layout from 'components/Layout';
import { ProtectedRoute } from 'components/ProtectedRoute';
import HomePage from 'pages/HomePage';
import AvalanchePage from 'pages/AvalanchePage';
import FantomPage from 'pages/FantomPage';
import EthereumPage from 'pages/EthereumPage';
import OptimismPage from 'pages/OptimismPage';
import CreditLimitPage from 'pages/CreditLimitPage';
import AddressManagementPage from 'pages/AddressManagementPage';
import EmergencyPage from './pages/EmergencyPage';
import AlphaHomoraPage from 'pages/AlphaHomoraPage';
import BalancePage from 'pages/BalancePage';
import AuthPage from 'pages/AuthPage';
import IBFFPage from 'pages/IBFFPage';

function App() {
  const onboard = useMemo(
    () =>
      init({
        wallets: [injectedModule()],
        chains: [
          {
            id: '0x1',
            token: 'ETH',
            label: 'Ethereum Mainnet',
            rpcUrl:
              'https://mainnet.infura.io/v3/bc71f79abd794bfdbebe82daca8a2dca',
          },
        ],
      }),
    []
  );

  useAutoOnboard();

  return (
    <BrowserRouter>
      <Web3OnboardProvider web3Onboard={onboard}>
        <Routes>
          <Route element={<Layout />}>
            <Route element={<ProtectedRoute />}>
              <Route path="markets/avalanche" element={<AvalanchePage />} />
              <Route path="markets/fantom" element={<FantomPage />} />
              <Route path="markets/ethereum" element={<EthereumPage />} />
              <Route path="markets/optimism" element={<OptimismPage />} />
              <Route path="creditlimits" element={<CreditLimitPage />} />
              <Route path="addresses" element={<AddressManagementPage />} />
              <Route path="emergency" element={<EmergencyPage />} />
              <Route path="alphahomora" element={<AlphaHomoraPage />} />
              <Route path="balances" element={<BalancePage />} />
              <Route path="ibff" element={<IBFFPage />} />
            </Route>
            <Route path="/" element={<HomePage />} />
            <Route path="auth" element={<AuthPage />} />
          </Route>
        </Routes>
      </Web3OnboardProvider>
    </BrowserRouter>
  );
}

export default App;
