import BN from 'bignumber.js';
import { Card, CardContent, Link } from '@mui/material';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import FlashOffIcon from '@mui/icons-material/FlashOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import { orderBy, filter } from 'lodash';
import { Protocol } from 'types';
import { Market } from 'hooks/useMarkets';
import AddressDisplay from 'components/AddressDisplay';
import ValueDisplay from 'components/ValueDisplay';
import SortableTable, {
  OrderType,
  SortedTableColumn,
} from 'components/SortableTable';
import { getBrowserLink } from 'helpers';

interface PauseTableProps {
  title?: string;
  protocol: Protocol;
  markets: Market[];
  isLoading: boolean;
}

interface MarketColumn extends SortedTableColumn {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render?: (row: Market) => any;
  sorting?: (row: Market) => number;
}

export const PauseTable = (props: PauseTableProps): JSX.Element => {
  const { title = 'Pauses', protocol, markets, isLoading = false } = props;

  const columns: MarketColumn[] = [
    {
      id: 'name',
      label: 'Name',
      align: 'left',
      sortable: true,
      render: (row: Market) => {
        return (
          <Link
            href={getBrowserLink(row.address, protocol)}
            target="_blank"
            rel="noreferrer"
          >
            {row.name}
          </Link>
        );
      },
    },
    {
      id: 'symbol',
      label: 'Symbol',
      align: 'left',
      sortable: true,
    },
    {
      id: 'address',
      label: 'Address',
      align: 'left',
      sortable: true,
      render: ({ address }) => (
        <AddressDisplay address={address} network={protocol} />
      ),
    },
    {
      id: 'implementation',
      label: 'Implementation',
      align: 'left',
      sortable: true,
      render: ({ implementation }) => (
        <AddressDisplay address={implementation} network={protocol} />
      ),
    },
    {
      id: 'flashloanPaused',
      label: 'Flashloan',
      align: 'center',
      sortable: true,
      render: ({ flashloanPaused }) => {
        return flashloanPaused ? (
          <FlashOffIcon fontSize="small" color="error" />
        ) : (
          <FlashOnIcon fontSize="small" color="secondary" />
        );
      },
    },
    {
      id: 'underlyingName',
      label: 'Underlying Name',
      align: 'left',
      sortable: true,
    },
    {
      id: 'underlyingSymbol',
      label: 'Underlying Symbol',
      align: 'left',
      sortable: true,
    },
    {
      id: 'underlyingAddress',
      label: 'Underlying Address',
      align: 'left',
      sortable: true,
      render: ({ underlyingAddress }) => (
        <AddressDisplay address={underlyingAddress} network={protocol} />
      ),
    },
    {
      id: 'underlyingDecimals',
      label: 'Underlying Decimals',
      align: 'right',
      sortable: true,
    },
    {
      id: 'interestRateModelAddress',
      label: 'Interest Rate Model Address',
      align: 'left',
      sortable: true,
      render: ({ interestRateModelAddress }) => (
        <AddressDisplay address={interestRateModelAddress} network={protocol} />
      ),
    },
    {
      id: 'collateralFactor',
      label: 'Collateral Factor(%)',
      align: 'right',
      sortable: true,
      sorting: ({ collateralFactor }) => collateralFactor?.toNumber() || -1,
      render: ({ collateralFactor }) =>
        collateralFactor ? `${collateralFactor.shiftedBy(2)}%` : 'no data',
    },
    {
      id: 'reserveFactor',
      label: 'Reserve Factor(%)',
      align: 'right',
      sortable: true,
      sorting: ({ reserveFactor }) => reserveFactor?.toNumber() || -1,
      render: ({ reserveFactor }) =>
        reserveFactor ? `${reserveFactor.shiftedBy(2)}%` : 'no data',
    },
    {
      id: 'collateralUsage',
      label: 'Collateral Usage(%)',
      align: 'right',
      sortable: true,
      sorting: ({ collateralUsage }) => {
        if (collateralUsage === null) {
          return -1;
        }

        return collateralUsage;
      },
      render: ({ collateralUsage }) => {
        if (collateralUsage === null) {
          return '--';
        }

        return `${collateralUsage}%`;
      },
    },
    {
      id: 'collateralTokens',
      label: 'Collateral Tokens',
      align: 'right',
      sortable: true,
      sorting: ({ collateralTokens }) => collateralTokens?.toNumber() || -1,
      render: ({ collateralTokens }) =>
        collateralTokens?.toFormat(2) || 'no data',
    },
    {
      id: 'collateralCap',
      label: 'Collateral Cap',
      align: 'right',
      sortable: true,
      sorting: ({ collateralCap }) =>
        new BN(collateralCap.cToken).toNumber() || -1,
      render: ({ collateralCap }) =>
        new BN(collateralCap.cToken).toFormat(2) || 'no data',
    },
    {
      id: 'underlyingPrice',
      label: 'Underlying Price',
      align: 'right',
      sortable: true,
      sorting: ({ underlyingPrice }) => underlyingPrice?.toNumber() || -1,
      render: ({ underlyingPrice }) =>
        underlyingPrice?.toFormat(2) || 'no data',
    },
    {
      id: 'collateralCapUnderlying',
      label: 'Underlying Collateral Cap',
      align: 'right',
      sortable: true,
      sorting: ({ collateralCap: { underlying } }) =>
        new BN(underlying).toNumber() || -1,
      render: ({ collateralCap: { underlying, usd } }) => {
        if (!underlying) {
          return 'no data';
        }
        return (
          <ValueDisplay
            value={new BN(underlying).toFormat(4) || 'no data'}
            usdValue={new BN(usd).toFormat(4) || 'no data'}
          />
        );
      },
    },
    {
      id: 'borrowPaused',
      label: 'Borrow',
      align: 'center',
      sortable: true,
      render: ({ borrowPaused }) =>
        borrowPaused ? (
          <PauseCircleIcon fontSize="small" color="error" />
        ) : (
          <CheckCircleIcon fontSize="small" color="success" />
        ),
    },
    {
      id: 'supplyPaused',
      label: 'Supply',
      align: 'center',
      sortable: true,
      render: ({ supplyPaused }) =>
        supplyPaused ? (
          <PauseCircleIcon fontSize="small" color="error" />
        ) : (
          <CheckCircleIcon fontSize="small" color="success" />
        ),
    },
  ];

  return (
    <Card>
      <CardContent>
        <SortableTable
          title={title}
          sx={{ maxHeight: '70vh' }}
          columns={columns}
          isLoading={isLoading}
          dataItems={filter(
            markets,
            (market) =>
              !market.delisted &&
              market.supplyPaused &&
              market.borrowPaused &&
              market.flashloanPaused
          )}
          defaultOrderKey="name"
          sortingFunc={(dataItems, orderKey, order) => {
            if (!dataItems) {
              return [];
            }
            if (orderKey !== 'borrowPaused' && orderKey !== 'supplyPaused') {
              return orderBy(
                dataItems,
                [orderKey, 'borrowPaused', 'supplyPaused'],
                [order, OrderType.Ascending, OrderType.Ascending]
              );
            }
            return orderBy(dataItems, [orderKey], [order]);
          }}
        />
      </CardContent>
    </Card>
  );
};
