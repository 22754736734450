import { useCallback, useMemo } from 'react';
import { JsonRpcProvider } from '@ethersproject/providers';
import { Contract } from '@ethersproject/contracts';
import { Multicall__factory } from 'contracts/types/factories/Multicall__factory';
import { map } from 'lodash';
import { Networks } from 'configurations';
import { Protocol } from 'types';

export interface Call {
  address: string;
  abi: Array<object>;
  fn: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: Array<any>;
}

export const useMulticall = (network: Protocol) => {
  const provider = useMemo(
    () => new JsonRpcProvider(Networks[network].providerURL),
    [network]
  );

  const multicall = useMemo(
    () =>
      Multicall__factory.connect(Networks[network].multicallAddress, provider),
    [network, provider]
  );

  const buildCall = (
    address: string,
    abi: Array<object>,
    fn: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params: any
  ): Call => {
    return {
      address,
      abi,
      fn,
      params,
    };
  };

  const multiCalls = useCallback(
    async (calls: Call[]) => {
      const abiInterfaces = map(calls, (call) =>
        Contract.getInterface(call.abi)
      );

      const args = map(calls, (call, index) => ({
        target: call.address.toLowerCase(),
        callData: abiInterfaces[index].encodeFunctionData(call.fn, call.params),
      }));

      try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [blockNumber, returnData] = await multicall.callStatic.aggregate(
          args
        );
        return map(returnData, (data, index) =>
          abiInterfaces[index].decodeFunctionResult(calls[index].fn, data)
        );
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    },
    [multicall.callStatic]
  );

  return {
    multiCalls,
    buildCall,
  };
};

export default useMulticall;
