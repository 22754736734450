import { useMemo } from 'react';
import {
  Navigate,
  createSearchParams,
  useLocation,
  Outlet,
} from 'react-router-dom';
import useUser from 'hooks/useUser';

export interface ProtectedRouteProps {
  isAuthenticated: boolean;
  authenticationPath: string;
  redirectPath: string;
  setRedirectPath: (path: string) => void;
}

const AuthenticationPath = '/auth';

export const ProtectedRoute = () => {
  const currentLocation = useLocation();
  const { isLogin } = useUser();

  const redirectPath = useMemo<string>(() => {
    return isLogin ? '' : currentLocation.pathname;
  }, [currentLocation.pathname, isLogin]);

  return isLogin ? (
    <Outlet />
  ) : (
    <Navigate
      to={{
        pathname: AuthenticationPath,
        search: `?${createSearchParams({
          redirectPath,
        }).toString()}`,
      }}
    />
  );
};

export default ProtectedRoute;
