import { find, isString, toLower, values } from 'lodash';
import { BigNumber, utils } from 'ethers';
import { BigNumber as BN } from 'bignumber.js';
import { AddressAlias, Network, Protocol } from 'types';
import { Networks } from 'configurations';
import { CTokenDecimal } from 'constants/CToken';

export const getUnderlyingPriceFromUsdPrice = (
  usdPrice: BigNumber,
  underlyingDecimals: number
): BN => {
  return new BN(utils.formatUnits(usdPrice, 18)).shiftedBy(
    underlyingDecimals - 18
  );
};

export const getUsdFromUnderlying = (
  cTokenAmount: BigNumber,
  exchangeRate: BigNumber,
  underlyingDecimals: number,
  usdPrice: BigNumber
): BN => {
  if (cTokenAmount.isZero() || exchangeRate.isZero() || usdPrice.isZero()) {
    return new BN(0);
  }

  const underlyingAmount = getUnderlyingFromCToken(
    cTokenAmount,
    exchangeRate,
    underlyingDecimals
  );

  if (underlyingAmount.isZero()) {
    return new BN(0);
  }

  const underlyingPrice = getUnderlyingPriceFromUsdPrice(
    usdPrice,
    underlyingDecimals
  );

  return underlyingAmount.multipliedBy(underlyingPrice);
};

export const getUnderlyingFromCToken = (
  cTokenAmount: BigNumber,
  exchangeRate: BigNumber,
  underlyingDecimals: number
): BN => {
  if (cTokenAmount.isZero() || exchangeRate.isZero()) {
    return new BN(0);
  }

  return new BN(utils.formatUnits(cTokenAmount, CTokenDecimal))
    .multipliedBy(utils.formatUnits(exchangeRate, 18))
    .shiftedBy(CTokenDecimal - underlyingDecimals);
};

export function getMultiCallAddress(network: Protocol): string {
  if (!Networks[network] || !Networks[network].multicallAddress) {
    return '';
  }
  return Networks[network].multicallAddress;
}

export function getProviderUrl(network: Protocol): string {
  if (!Networks[network] || !Networks[network].providerURL) {
    return '';
  }
  return Networks[network].providerURL;
}

export function getNetworkEndpoint(network: Protocol): string {
  if (!Networks[network] || !Networks[network].subgraph) {
    return '';
  }
  return Networks[network].subgraph;
}

export function getShortAddress(address: string): string {
  if (!address || !isString(address)) {
    return 'invalid address';
  }

  if (address.length < 12) {
    return address;
  }

  return `${address.slice(0, 7)}.....${address.slice(-5)}`;
}

export function getNetworkName(network: string): string {
  if (!Networks[network] || !Networks[network].name) {
    return '';
  }
  return Networks[network].name;
}

export function isSameAddress(addressA: string, addressB: string): boolean {
  return toLower(addressA) === toLower(addressB);
}

export function getFormattedAddress(
  network: Protocol,
  address: string,
  addressCollection: AddressAlias[]
): AddressAlias {
  const alias = find(
    addressCollection,
    (item) => item.network === network && isSameAddress(item.address, address)
  );

  if (!alias) {
    return {
      id: '',
      network: Protocol.Unknown,
      address,
      name: '',
    };
  }

  return alias;
}

export function getBrowserLink(address: string, network: Protocol): string {
  if (!Networks[network] || !Networks[network].browser) {
    return '';
  }
  return `${Networks[network].browser}/address/${address}`;
}

export function getNetworkByChainId(chainId: number): Network | null {
  return find(values(Networks), ['chainId', chainId]) || null;
}

export function getNetworkByHexChainId(hexChainId: string): Network | null {
  const chainId = Number(hexChainId);
  return getNetworkByChainId(chainId);
}
