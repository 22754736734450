import { useWeb3Onboard } from '@web3-onboard/react/dist/context';
import { useEffect } from 'react';

function useAutoOnboard() {
  const onboard = useWeb3Onboard();

  useEffect(() => {
    const walletsSub = onboard.state.select('wallets');

    const { unsubscribe } = walletsSub.subscribe((wallets) => {
      const connectedWallets = wallets.map(({ label }) => label);
      window.localStorage.setItem(
        'connectedWallets',
        JSON.stringify(connectedWallets)
      );
    });

    return () => {
      try {
        unsubscribe();
      } catch (error) {
        console.log('onboard', error);
      }
    };
  }, [onboard.state]);

  useEffect(() => {
    const previouslyConnectedWallets = JSON.parse(
      window.localStorage.getItem('connectedWallets') || 'null'
    );

    if (previouslyConnectedWallets && previouslyConnectedWallets[0]) {
      onboard.connectWallet({
        autoSelect: {
          label: previouslyConnectedWallets[0],
          disableModals: true,
        },
      });
    }
  }, [onboard]);
}

export default useAutoOnboard;
