import { Stack, Box } from '@mui/material';

interface ValueDisplayProps {
  value: string;
  usdValue: string;
}

const ValueDisplay = (props: ValueDisplayProps) => {
  const { value, usdValue } = props;

  return (
    <Stack direction="column" gap={1}>
      <Box>{value}</Box>
      <Box>${usdValue}</Box>
    </Stack>
  );
};

export default ValueDisplay;
