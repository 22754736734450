export type Network = {
  id: Protocol;
  chainId: number;
  name: string;
  providerURL: string;
  unitrollerAddress: string;
  multicallAddress: string;
  guardianSafeAddress: string;
  multisendAddress: string;
  subgraph: string;
  browser: string;
  priceOracleAddress: string;
};

export enum Protocol {
  Ethereum = 'ethereum',
  Fantom = 'fantom',
  Avalanche = 'avalanche',
  Optimism = 'optimism',
  Unknown = '',
}

export type AddressAlias = {
  id: string;
  network: Protocol;
  address: string;
  name: string;
};
