import { useState } from 'react';
import {
  Card,
  Box,
  FormControl,
  MenuItem,
  FormLabel,
  TextField,
  Stack,
  IconButton,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { map } from 'lodash';
import { Formik, Field, Form } from 'formik';
import { BalanceCard } from 'components/BalanceCard';
import { Protocol } from 'types';
import { Networks } from 'configurations';

export const SearchBalanceCard = () => {
  const [borrower, setBorrower] = useState<string>('');
  const [network, setNetwork] = useState<Protocol>(Protocol.Ethereum);

  return (
    <Formik
      initialValues={{
        network: Protocol.Ethereum,
        borrower: '',
      }}
      onSubmit={({ network, borrower }) => {
        setBorrower(borrower);
        setNetwork(network);
      }}
    >
      <Card>
        <Box sx={{ p: 1 }}>
          <Typography variant="h6">Borrower Balance</Typography>
        </Box>
        <Box sx={{ p: 1 }}>
          <Form>
            <Stack direction="row" spacing={2} alignItems="flex-end">
              <FormControl sx={{ minWidth: 150 }}>
                <FormLabel>Network</FormLabel>
                <Field as={TextField} name="network" select size="small">
                  {map(Networks, (network) => (
                    <MenuItem key={network.id} value={network.id}>
                      {network.name}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>
              <FormControl fullWidth>
                <FormLabel>Borrower Address</FormLabel>
                <Field as={TextField} name="borrower" size="small" />
              </FormControl>
              <IconButton type="submit">
                <SearchIcon />
              </IconButton>
            </Stack>
          </Form>
          {network && borrower && (
            <BalanceCard
              borrower={borrower}
              network={network}
              title="Search Result"
            />
          )}
        </Box>
      </Card>
    </Formik>
  );
};

export default SearchBalanceCard;
