import { useMemo } from 'react';
import { useQueries } from '@tanstack/react-query';
import BN from 'bignumber.js';
import { parseISO } from 'date-fns';
import { map, every, reduce } from 'lodash';
import axios from 'axios';
import { IronBankApiURL } from 'configurations';
import { Protocol } from 'types';

export type RawCreditUsage = {
  account: string;
  market: string;
  symbol: string;
  underlyingDecimals: string;
  borrowBalanceStored: string;
  creditLimit: string;
  usage: string;
  date: string;
};

export type CreditUsage = {
  market: string;
  account: string;
  symbol: string;
  decimals: number;
  data: UsageData[];
};

export type UsageData = {
  borrowBalanceStored: number;
  creditLimit: number;
  usage: number;
  date: Date;
};

export const useCreditLimitUsages = (
  network: Protocol,
  account: string,
  markets: string[]
): {
  creditUsages: CreditUsage[];
  isLoading: boolean;
  isFetching: boolean;
} => {
  const fetchAccountMarket = async (
    network: Protocol,
    account: string,
    market: string
  ): Promise<CreditUsage | undefined> => {
    const resp = await axios.get<RawCreditUsage[]>(
      `${IronBankApiURL}/api/v1/creditLimitUsage`,
      {
        params: {
          comptroller: network === Protocol.Ethereum ? 'eth' : network,
          account,
          market,
        },
      }
    );

    if (!resp || !resp.data) {
      return undefined;
    }

    const symbol = resp.data[0].symbol;
    const decimals = new BN(resp.data[0].underlyingDecimals).toNumber();

    return {
      market,
      symbol,
      account,
      decimals,
      data: map(
        resp.data,
        ({ date, borrowBalanceStored, creditLimit, usage }) => {
          return {
            creditLimit: new BN(creditLimit)
              .shiftedBy(-1 * decimals)
              .toNumber(),
            borrowBalanceStored: new BN(borrowBalanceStored)
              .shiftedBy(-1 * decimals)
              .toNumber(),
            usage: new BN(usage).toNumber(),
            date: parseISO(date),
          };
        }
      ),
    };
  };

  const queryResults = useQueries({
    queries: map(markets, (market) => {
      return {
        queryKey: ['credit-usages', network, account, market],
        queryFn: () => fetchAccountMarket(network, account, market),
      };
    }),
  });

  const creditUsages = useMemo<CreditUsage[]>(() => {
    return reduce(
      queryResults,
      (acc: CreditUsage[], result) => {
        if (!result || !result.data) {
          return acc;
        }

        return acc.concat(result.data);
      },
      []
    );
  }, [queryResults]);

  return {
    creditUsages,
    isLoading: !every(queryResults, ['isLoading', false]),
    isFetching: !every(queryResults, ['isFetching', false]),
  };
};

export default useCreditLimitUsages;
