import { useMemo } from 'react';
import { ethers, BigNumber } from 'ethers';
import { flatten, map, reduce, some } from 'lodash';
import { useQueries } from '@tanstack/react-query';
import { IBFFBorrowerCurveLP__factory } from 'contracts/types';
import useMulticall from 'hooks/useMultiCall';
import { Protocol } from 'types';

const IBFFAddress = '0x9A97664f3aBA3d6De05099b513a854D838c99Db6';
const ForexKeys = [0, 1, 2, 3, 4, 5, 6];
const USDCDecimal = 6;

export type ForexData = {
  id: string;
  name: string;
  underlying: string;
  borrowedValueInUsdc: string;
  claimableProfitInUsdc: string;
  holdingsValueInUsdc: string;
};

type ForexInfo = {
  name: string;
  pid: BigNumber;
  underlying: string;
  synth: string;
  cyToken: string;
  curveLpToken: string;
  curvePool: string;
  rewardsContract: string;
  borrowLimit: BigNumber;
  borrowAmountStored: BigNumber;
  chainlinkUint: BigNumber;
  currencyKey: string;
};

export const useIBFFData = () => {
  const { multiCalls, buildCall } = useMulticall(Protocol.Ethereum);

  const [
    { data: forexInfo, isLoading: forexInfoLoading },
    { data: borrowedValueInUsdc, isLoading: borrowedValueInUsdcLoading },
    { data: claimableProfitInUsdc, isLoading: claimableProfitInUsdcLoading },
    { data: holdingsValueInUsdc, isLoading: holdingsValueInUsdcLoading },
  ] = useQueries({
    queries: [
      {
        queryKey: ['ibff-forex-info'],
        queryFn: async (): Promise<ForexInfo[]> => {
          const calls = map(ForexKeys, (key) =>
            buildCall(
              IBFFAddress,
              IBFFBorrowerCurveLP__factory.abi,
              'forexInfo',
              [key]
            )
          );

          const result = await multiCalls(calls);
          return map(result, (res) => {
            return {
              name: res.name,
              pid: res.pid,
              underlying: res.underlying,
              synth: res.synth,
              cyToken: res.cyToken,
              curveLpToken: res.curveLpToken,
              curvePool: res.curvePool,
              rewardsContract: res.rewardsContract,
              borrowLimit: res.borrowLimit,
              borrowAmountStored: res.borrowAmountStored,
              chainlinkUint: res.chainlinkUint,
              currencyKey: res.currencyKey,
            };
          });
        },
      },
      {
        queryKey: ['ibff-borrow-value-in-usdc'],
        queryFn: async (): Promise<BigNumber[]> => {
          const calls = map(ForexKeys, (key) =>
            buildCall(
              IBFFAddress,
              IBFFBorrowerCurveLP__factory.abi,
              'borrowedValueInUsdc',
              [key]
            )
          );

          const result = await multiCalls(calls);
          return flatten(result);
        },
      },
      {
        queryKey: ['ibff-claimable-profit-in-usdc'],
        queryFn: async (): Promise<BigNumber[]> => {
          const calls = map(ForexKeys, (key) =>
            buildCall(
              IBFFAddress,
              IBFFBorrowerCurveLP__factory.abi,
              'claimableProfitInUsdc',
              [key]
            )
          );

          const result = await multiCalls(calls);
          return flatten(result);
        },
      },
      {
        queryKey: ['ibff-holdings-value-in-usdc'],
        queryFn: async (): Promise<BigNumber[]> => {
          const calls = map(ForexKeys, (key) =>
            buildCall(
              IBFFAddress,
              IBFFBorrowerCurveLP__factory.abi,
              'holdingsValueInUsdc',
              [key]
            )
          );

          const result = await multiCalls(calls);
          return flatten(result);
        },
      },
    ],
  });

  const ibffData = useMemo<ForexData[]>(() => {
    if (!forexInfo) {
      return [];
    }

    return reduce(
      forexInfo,
      (acc, forex, index) => {
        const borrowedValue = borrowedValueInUsdc
          ? ethers.utils.formatUnits(borrowedValueInUsdc[index], USDCDecimal)
          : '0';

        const claimableProfit = claimableProfitInUsdc
          ? ethers.utils.formatUnits(claimableProfitInUsdc[index], USDCDecimal)
          : '0';

        const holdingsValue = holdingsValueInUsdc
          ? ethers.utils.formatUnits(holdingsValueInUsdc[index], USDCDecimal)
          : '0';

        return acc.concat({
          id: `${forex.underlying}-${forex.name}}`,
          name: forex.name,
          underlying: forex.underlying,
          borrowedValueInUsdc: borrowedValue,
          claimableProfitInUsdc: claimableProfit,
          holdingsValueInUsdc: holdingsValue,
        });
      },
      [] as ForexData[]
    );
  }, [
    borrowedValueInUsdc,
    claimableProfitInUsdc,
    forexInfo,
    holdingsValueInUsdc,
  ]);

  return {
    data: ibffData,
    isLoading: some(
      [
        forexInfoLoading,
        borrowedValueInUsdcLoading,
        claimableProfitInUsdcLoading,
        holdingsValueInUsdcLoading,
      ],
      true
    ),
  };
};

export default useIBFFData;
