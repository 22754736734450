import { useMemo } from 'react';
import { orderBy, find, map } from 'lodash';
import { utils } from 'ethers';
import BN from 'bignumber.js';
import { Protocol } from 'types';
import { Card, CardContent } from '@mui/material';
import { CreditLimit } from 'hooks/useCreditLimits';
import AddressDisplay from 'components/AddressDisplay';
import SortableTable, { SortedTableColumn } from 'components/SortableTable';
import { CreditUsageChart } from 'components/CreditUsageChart';

interface CreditLimitColumn extends SortedTableColumn {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render?: (row: CreditLimit) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sorting?: (row: CreditLimit) => any;
}

interface CreditLimitsProps {
  title?: string;
  protocol: Protocol;
  creditLimits: CreditLimit[];
  isLoading: boolean;
  isShowHistory?: boolean;
}

export const CreditLimits = (props: CreditLimitsProps): JSX.Element => {
  const {
    title,
    protocol,
    creditLimits,
    isLoading,
    isShowHistory = false,
  } = props;
  const columns: CreditLimitColumn[] = [
    {
      id: 'market.underlyingSymbol',
      label: 'Symbol',
      align: 'left',
      sortable: true,
    },
    {
      id: 'borrower',
      label: 'Borrower',
      align: 'left',
      sortable: true,
      render: ({ borrower }) => (
        <AddressDisplay address={borrower} network={protocol} />
      ),
    },
    {
      id: 'creditLimit',
      label: 'Credit Limit',
      align: 'right',
      sortable: true,
      sorting: ({ creditLimit }) => creditLimit?.toString(),
      render: ({ creditLimit, market: { underlyingDecimals } }) =>
        creditLimit === null || !creditLimit
          ? 'No Data'
          : new BN(utils.formatUnits(creditLimit, underlyingDecimals)).toFormat(
              2
            ),
    },
    {
      id: 'borrowBalance',
      label: 'Borrow Balance',
      align: 'right',
      sortable: true,
      sorting: ({ borrowBalance }) => borrowBalance?.toString(),
      render: ({ borrowBalance, market: { underlyingDecimals } }) =>
        borrowBalance === null || !borrowBalance
          ? 'No data'
          : new BN(
              utils.formatUnits(borrowBalance, underlyingDecimals)
            ).toFormat(2),
    },
    {
      id: 'borrowRate',
      label: 'Borrow / Limit (%)',
      align: 'right',
      sortable: true,
      render: ({ borrowBalance, creditLimit }) => {
        if (!creditLimit || creditLimit.isZero()) {
          return '--';
        }

        if (!borrowBalance || borrowBalance.isZero()) {
          return '0%';
        }

        return `${new BN(borrowBalance.toString())
          .dividedBy(new BN(creditLimit.toString()))
          .shiftedBy(2)
          .toFixed(2)} %`;
      },
      sorting: ({ borrowBalance, creditLimit }) => {
        if (!borrowBalance || borrowBalance.isZero()) {
          return 0;
        }

        if (!creditLimit || creditLimit.isZero()) {
          return -1;
        }

        return new BN(borrowBalance.toString())
          .dividedBy(new BN(creditLimit.toString()))
          .shiftedBy(2)
          .toNumber();
      },
    },
  ];

  const dataList = useMemo<CreditLimit[]>(() => {
    if (!isShowHistory) {
      return creditLimits;
    }
    return map(creditLimits, (creditLimit) => ({
      ...creditLimit,
      expandComponent: (
        <CreditUsageChart
          network={protocol}
          account={creditLimit.borrower}
          market={creditLimit.market.address}
        />
      ),
    }));
  }, [creditLimits, isShowHistory, protocol]);

  return (
    <Card>
      <CardContent>
        <SortableTable
          title={title}
          sx={{ maxHeight: '70vh' }}
          isLoading={isLoading}
          columns={columns}
          isExpandable={isShowHistory}
          dataItems={dataList}
          sortingFunc={(dataItems, orderKey, order) => {
            if (!dataItems) {
              return [];
            }
            const columnData = find(columns, ['id', orderKey]);

            if (!columnData || !columnData.sorting) {
              return orderBy(dataItems, [orderKey], [order]);
            }

            return orderBy(dataItems, columnData.sorting, [order]);
          }}
          defaultOrderKey="market.underlyingSymbol"
        />
      </CardContent>
    </Card>
  );
};

export default CreditLimits;
