import { Box, Tabs, Tab } from '@mui/material';
import { Protocol } from 'types';
import { Markets } from 'components/Markets';
import { PauseTable } from 'components/PauseTable';
import { TabPanel } from 'components/TabPanel';
import useMarkets from 'hooks/useMarkets';
import useTabs from 'hooks/useTab';

function FantomPage() {
  const { listed, softDelisted, isFetching, isSuccess } = useMarkets(
    Protocol.Fantom
  );
  const { tab, handleChange, getA11yProps } = useTabs();

  return (
    <section>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tab} onChange={handleChange} aria-label="tabs">
          <Tab label="Market" {...getA11yProps(0)} />
          <Tab label="Paused" {...getA11yProps(1)} />
          <Tab label="Soft Delisted" {...getA11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={tab} index={0}>
        <Markets
          title="Fantom Markets"
          protocol={Protocol.Fantom}
          markets={isSuccess ? listed : []}
          loading={isFetching}
        />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <PauseTable
          title="Fantom Paused"
          protocol={Protocol.Fantom}
          markets={isSuccess ? listed : []}
          isLoading={isFetching}
        />
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <Markets
          title="Fantom Soft Delisted"
          protocol={Protocol.Fantom}
          markets={softDelisted ? softDelisted : []}
          loading={isFetching}
        />
      </TabPanel>
    </section>
  );
}

export default FantomPage;
