import { useState, useMemo, ReactNode } from 'react';
import BN from 'bignumber.js';
import { BigNumber } from 'ethers';
import {
  map,
  orderBy,
  isEmpty,
  get,
  filter,
  isString,
  isNumber,
  find,
} from 'lodash';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableContainerProps,
  TableRow,
  LinearProgress,
  Box,
  Typography,
  Toolbar,
  FormControlLabel,
  Switch,
} from '@mui/material';
import SortableTableHead from './SortableTableHead';
import ExpandableTableRow from './ExpandableTableRow';

export enum OrderType {
  Ascending = 'asc',
  Descending = 'desc',
}

export type SortedTableColumn = {
  id: string;
  align: 'right' | 'left' | 'center';
  label: string | ReactNode;
  sortable: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

interface SortableTableProps<T> extends TableContainerProps {
  title?: string;
  isLoading: boolean;
  columns: SortedTableColumn[];
  dataItems: T[];
  sortingFunc?: (dataItems: T[], orderKey: string, order: OrderType) => T[];
  defaultOrderKey?: string;
  enableZeroFilter?: boolean;
  zeroFilter?: keyof T;
  isExpandable?: boolean;
}

export const SortableTable = <
  T extends { id: string; expandComponent?: ReactNode }
>(
  props: SortableTableProps<T>
): JSX.Element => {
  const {
    title = '',
    isLoading,
    columns,
    dataItems,
    sortingFunc,
    defaultOrderKey,
    enableZeroFilter = false,
    zeroFilter,
    isExpandable = false,
    ...restProps
  } = props;

  const [order, setOrder] = useState<OrderType>(OrderType.Ascending);
  const [orderKey, setOrderKey] = useState<string>(
    defaultOrderKey || columns[0].id
  );
  const [isHideZero, setIsHideZero] = useState<boolean>(false);

  const filteredList: T[] = useMemo(() => {
    if (!enableZeroFilter || !zeroFilter) {
      return dataItems;
    }

    if (!isHideZero) {
      return dataItems;
    }

    return filter(dataItems, (item) => {
      const itemValue = item[zeroFilter];
      if (BigNumber.isBigNumber(itemValue)) {
        return !itemValue.isZero();
      }

      if (BN.isBigNumber(itemValue)) {
        return !itemValue.isZero();
      }

      if (isString(itemValue)) {
        return !(itemValue === '0' || itemValue === '0.0');
      }

      if (isNumber(itemValue)) {
        return itemValue !== 0;
      }

      return true;
    });
  }, [dataItems, enableZeroFilter, isHideZero, zeroFilter]);

  const sortedList: T[] = useMemo(() => {
    if (sortingFunc) {
      return sortingFunc(filteredList, orderKey, order);
    }

    if (!filteredList) {
      return [];
    }

    const columnData = find(columns, ['id', orderKey]);

    if (!columnData || !columnData.sorting) {
      return orderBy(dataItems, [orderKey], [order]);
    }

    return orderBy(dataItems, columnData.sorting, [order]);
  }, [sortingFunc, filteredList, columns, orderKey, dataItems, order]);

  return (
    <Box>
      {title && <Typography variant="h5">{title}</Typography>}
      {enableZeroFilter && (
        <Toolbar variant="dense">
          {enableZeroFilter && (
            <FormControlLabel
              control={
                <Switch
                  checked={isHideZero}
                  onChange={(event) => setIsHideZero(event.target.checked)}
                  size="small"
                />
              }
              label="Hide Zero value"
            />
          )}
        </Toolbar>
      )}
      <TableContainer {...restProps}>
        {isLoading && <LinearProgress />}
        <Table stickyHeader size="small">
          <SortableTableHead
            columns={columns}
            order={order}
            orderBy={orderKey}
            isExpandable={isExpandable}
            onSort={(id) => {
              const isAsc = orderKey === id && order === OrderType.Ascending;
              setOrderKey(id);
              setOrder(isAsc ? OrderType.Descending : OrderType.Ascending);
            }}
          />
          <TableBody>
            {map(sortedList, ({ expandComponent, ...row }, index) => {
              return isExpandable && expandComponent ? (
                <ExpandableTableRow
                  key={row.id}
                  columns={columns}
                  rowData={row}
                  expandComponent={expandComponent}
                />
              ) : (
                <TableRow key={row.id} selected={index % 2 === 0}>
                  {map(columns, (column) => (
                    <TableCell key={column.id} align={column.align}>
                      {column.render ? column.render(row) : get(row, column.id)}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {!isLoading && isEmpty(sortedList) && (
          <Box sx={{ py: 2 }}>
            <Typography align="center" variant="body2">
              No data
            </Typography>
          </Box>
        )}
      </TableContainer>
    </Box>
  );
};

export default SortableTable;
