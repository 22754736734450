import { JsonRpcProvider } from '@ethersproject/providers';
import { Comptroller__factory } from 'contracts/types/factories/Comptroller__factory';
import { Networks } from 'configurations';
import { Protocol } from 'types';
import { getProviderUrl } from 'helpers';

export function useComptroller(network: Protocol) {
  const provider = new JsonRpcProvider(getProviderUrl(network));
  const comptroller = Comptroller__factory.connect(
    Networks[network].unitrollerAddress,
    provider
  );

  return {
    comptroller,
    address: Networks[network].unitrollerAddress,
    abi: Comptroller__factory.abi,
  };
}

export default useComptroller;
