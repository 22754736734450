import { utils } from 'ethers';
import { map } from 'lodash';
import BN from 'bignumber.js';
import { Typography, Box } from '@mui/material';
import { useBalances, BorrowerBalance } from 'hooks/useBalances';
import SortableTable, { SortedTableColumn } from 'components/SortableTable';
import AddressDisplay from 'components/AddressDisplay';
import { Protocol } from 'types';

type BorrowerBalanceRow = BorrowerBalance & {
  id: string;
};

interface BalancesColumn extends SortedTableColumn {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render?: (row: BorrowerBalanceRow) => any;
}

interface BalanceCardProps {
  borrower: string;
  network: Protocol;
  title?: string;
}

export const BalanceCard = ({
  borrower,
  network,
  title,
}: BalanceCardProps): JSX.Element => {
  const { marketBalances, isLoading } = useBalances(network, borrower);

  const columns: BalancesColumn[] = [
    {
      id: 'underlyingName',
      label: 'Name',
      align: 'left',
      sortable: true,
    },
    {
      id: 'underlyingSymbol',
      label: 'Symbol',
      align: 'left',
      sortable: true,
    },
    {
      id: 'balance',
      label: 'Borrow Balance',
      align: 'right',
      sortable: true,
      render: ({ balance, underlyingDecimals }) => {
        if (!balance) {
          return '--';
        }
        return new BN(utils.formatUnits(balance, underlyingDecimals)).toFormat(
          2
        );
      },
    },
  ];

  return (
    <Box sx={{ p: 1 }}>
      <Box sx={{ my: 1 }}>
        {title ? (
          <Typography variant="subtitle1">{title}</Typography>
        ) : (
          <>
            <Typography variant="caption">{network}</Typography>
            <AddressDisplay address={borrower} network={network} />
          </>
        )}
      </Box>
      <Box>
        <SortableTable
          isLoading={isLoading}
          columns={columns}
          dataItems={map(marketBalances, (marketBalance) => ({
            ...marketBalance,
            id: marketBalance.address,
          }))}
        />
      </Box>
    </Box>
  );
};

export default BalanceCard;
