import { styled } from '@mui/system';
import useAddress from 'hooks/useAddress';
import { Formik, Field, Form } from 'formik';
import {
  Button,
  FormControl,
  TextField,
  Stack,
  DialogActions,
  DialogContent,
  MenuItem,
} from '@mui/material';
import { map } from 'lodash';
import useDialog from 'hooks/useDialog';
import AddressTable from 'components/AddressTable';
import { Networks } from 'configurations';
import { Protocol } from 'types';

function AddressManagementPage() {
  const {
    addressAlias,
    createAddress,
    deleteAddress,
    updateAddress,
    isFetching,
  } = useAddress();
  const [openDialog, closeDialog] = useDialog();

  const onAdd = () => {
    openDialog({
      title: 'Add Address',
      children: (
        <Formik
          initialValues={{
            network: '',
            name: '',
            address: '',
          }}
          onSubmit={({ network, name, address }) => {
            createAddress(network as Protocol, name, address);
            closeDialog();
          }}
        >
          <Form>
            <DialogContent>
              <Stack>
                <FormControl sx={{ p: 1 }}>
                  <Field
                    as={TextField}
                    select
                    name="network"
                    label="Network"
                    variant="outlined"
                  >
                    {map(Networks, (network, key) => (
                      <MenuItem key={key} value={network.id}>
                        {network.name}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
                <FormControl sx={{ p: 1 }}>
                  <Field
                    as={TextField}
                    name="name"
                    label="Name"
                    variant="outlined"
                  />
                </FormControl>
                <FormControl sx={{ p: 1 }}>
                  <Field
                    as={TextField}
                    name="address"
                    label="Address"
                    variant="outlined"
                  />
                </FormControl>
              </Stack>
            </DialogContent>
            <CenteredDialogActions>
              <Button variant="outlined" onClick={closeDialog}>
                Cancel
              </Button>
              <Button color="primary" variant="contained" type="submit">
                Add
              </Button>
            </CenteredDialogActions>
          </Form>
        </Formik>
      ),
    });
  };

  const onUpdate = (
    id: string,
    oldNetwork: Protocol,
    oldName: string,
    oldAddress: string
  ) => {
    openDialog({
      title: 'Update Address',
      children: (
        <Formik
          initialValues={{
            network: oldNetwork,
            name: oldName,
            address: oldAddress,
          }}
          onSubmit={({ network, name, address }) => {
            updateAddress(id, network as Protocol, name, address);
            closeDialog();
          }}
        >
          <Form>
            <DialogContent>
              <Stack>
                <FormControl sx={{ p: 1 }}>
                  <Field
                    as={TextField}
                    select
                    name="network"
                    label="Network"
                    variant="outlined"
                  >
                    {map(Networks, (network, key) => (
                      <MenuItem key={key} value={network.id}>
                        {network.name}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
                <FormControl sx={{ p: 1 }}>
                  <Field
                    as={TextField}
                    name="name"
                    label="Name"
                    variant="outlined"
                  />
                </FormControl>
                <FormControl sx={{ p: 1 }}>
                  <Field
                    as={TextField}
                    name="address"
                    label="Address"
                    variant="outlined"
                  />
                </FormControl>
              </Stack>
            </DialogContent>
            <CenteredDialogActions>
              <Button variant="outlined" onClick={closeDialog}>
                Cancel
              </Button>
              <Button color="primary" variant="contained" type="submit">
                Update
              </Button>
            </CenteredDialogActions>
          </Form>
        </Formik>
      ),
    });
  };

  const onDelete = (id: string, network: Protocol, address: string) => {
    openDialog({
      title: 'Delete Address',
      children: (
        <>
          <DialogContent>
            <FormControl fullWidth sx={{ p: 1 }}>
              <TextField
                select
                label="Deleting network"
                value={network}
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
                error
              >
                {map(Networks, (network, key) => (
                  <MenuItem key={key} value={network.id}>
                    {network.name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
            <FormControl fullWidth sx={{ p: 1 }}>
              <TextField
                label="Deleting address"
                value={address}
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
                error
              />
            </FormControl>
          </DialogContent>
          <CenteredDialogActions>
            <Button variant="outlined" onClick={closeDialog}>
              Cancel
            </Button>
            <Button
              color="error"
              variant="contained"
              onClick={() => {
                deleteAddress(id);
                closeDialog();
              }}
            >
              Delete
            </Button>
          </CenteredDialogActions>
        </>
      ),
    });
  };

  return (
    <section>
      <AddressTable
        loading={isFetching}
        addresses={addressAlias}
        onAdd={onAdd}
        onEdit={onUpdate}
        onDelete={onDelete}
      />
    </section>
  );
}

const CenteredDialogActions = styled(DialogActions)({
  display: 'flex',
  justifyContent: 'center',
});

export default AddressManagementPage;
