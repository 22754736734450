import { Network, Protocol } from 'types';

export const IronBankApiURL = 'https://api.ib.xyz';
export const IronBankApiURLDev = 'https://dev-api.ib.xyz';

export const EthereumNetwork: Network = {
  id: Protocol.Ethereum,
  chainId: 1,
  name: 'Ethereum',
  providerURL: 'https://cloudflare-eth.com/',
  unitrollerAddress: '0xAB1c342C7bf5Ec5F02ADEA1c2270670bCa144CbB',
  multicallAddress: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
  guardianSafeAddress: '0x9d960dAe0639C95a0C822C9d7769d19d30A430Aa',
  multisendAddress: '0x40A2aCCbd92BCA938b02010E17A5b8929b49130D',
  subgraph:
    'https://gateway-arbitrum.network.thegraph.com/api/6bee5a4b87e6f1624ca8098b0308fe0f/subgraphs/id/5XDK77KqWtqEBmR1xoMKyypK7RkgPvK61c1yNw3j8SYs',
  browser: 'https://etherscan.io',
  priceOracleAddress: '0x19fE4A2aDa33bD690c66a99DF3DE6c0899b6bd8F',
};

export const FantomNetwork: Network = {
  id: Protocol.Fantom,
  chainId: 250,
  name: 'Fantom',
  providerURL: 'https://rpc.ftm.tools/',
  unitrollerAddress: '0x4250A6D3BD57455d7C6821eECb6206F507576cD2',
  multicallAddress: '0x11e516AA99eD87D3076a5e5Fc842FA63e181F4C2',
  guardianSafeAddress: '0x9d960dAe0639C95a0C822C9d7769d19d30A430Aa',
  multisendAddress: '0x10B62CC1E8D9a9f1Ad05BCC491A7984697c19f7E',
  subgraph:
    'https://api.thegraph.com/subgraphs/name/reganwrench/iron-bank-fantom',
  browser: 'https://ftmscan.com',
  priceOracleAddress: '0xa211558d6D6053aEDb82e3ECD0D4632F15A2D554',
};

export const AvalancheNetwork: Network = {
  id: Protocol.Avalanche,
  chainId: 43114,
  name: 'Avalanche',
  providerURL: 'https://api.avax.network/ext/bc/C/rpc',
  unitrollerAddress: '0x2eE80614Ccbc5e28654324a66A396458Fa5cD7Cc',
  multicallAddress: '0xa00FB557AA68d2e98A830642DBbFA534E8512E5f',
  guardianSafeAddress: '0x93C220cf1Db6ea5Ab593180ccffA7C0C63A9767E',
  multisendAddress: '0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B',
  subgraph:
    'https://api.thegraph.com/subgraphs/name/ironbankdevz/ironbank-avax',
  browser: 'https://snowtrace.io',
  priceOracleAddress: '0x0980f2F0D2af35eF2c4521b2342D59db575303F7',
};

export const OptimismNetwork: Network = {
  id: Protocol.Optimism,
  chainId: 10,
  name: 'Optimism',
  providerURL:
    'https://optimism-mainnet.infura.io/v3/7249ca3bbff34204bdae3441cdde81fb',
  unitrollerAddress: '0xE0B57FEEd45e7D908f2d0DaCd26F113Cf26715BF',
  multicallAddress: '0xeAa6877139d436Dc6d1f75F3aF15B74662617B2C',
  guardianSafeAddress: '0xa0FF78Cc79e9061E013A186D1DC34f8FdE9a0576',
  multisendAddress: '0x998739BFdAAdde7C933B942a68053933098f9EDa',
  subgraph:
    'https://api.thegraph.com/subgraphs/name/ibdotxyz/iron-bank-optimism',
  browser: 'https://optimistic.etherscan.io/',
  priceOracleAddress: '0x2424C30E589Caea191C06F41d1f5b90348dbeD7d',
};

export const Networks: { [key: string]: Network } = {
  ethereum: EthereumNetwork,
  fantom: FantomNetwork,
  avalanche: AvalancheNetwork,
  optimism: OptimismNetwork,
};
