import { Web3Provider } from '@ethersproject/providers';
import {
  Button,
  Checkbox,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useConnectWallet, useSetChain } from '@web3-onboard/react';
import AddressDisplay from 'components/AddressDisplay';
import {
  CErc20__factory,
  Comptroller__factory,
  Erc20__factory,
  GnosisSafe__factory,
  MultiSendCallOnly__factory,
} from 'contracts/types';
import { BytesLike, ethers, Signer } from 'ethers';
import {
  hexConcat,
  hexDataLength,
  hexValue,
  hexZeroPad,
} from 'ethers/lib/utils';
import { Form, Formik, FormikConfig, useFormikContext } from 'formik';
import { getNetworkByHexChainId } from 'helpers';
import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Network } from 'types';

type Market = {
  ibTokenAddress: string;
  underlyingSymbol: string;
};

type FormValue = {
  mint: string[];
  borrow: string[];
  flashloan: string[];
};

const EmergencyPage = (): JSX.Element => {
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
  const [{ connectedChain }] = useSetChain();

  const provider = useMemo(() => {
    if (!wallet) return null;
    return new Web3Provider(wallet.provider);
  }, [wallet]);

  const network = useMemo(() => {
    if (!connectedChain) return null;
    return getNetworkByHexChainId(connectedChain.id);
  }, [connectedChain]);

  const comptroller = useMemo(() => {
    if (!network || !provider) return null;
    return Comptroller__factory.connect(network.unitrollerAddress, provider);
  }, [network, provider]);

  const { data: marketData } = useQuery<Market[]>(
    ['markets', connectedChain],
    async () => {
      if (!comptroller || !provider) return [];

      const markets = await comptroller.getAllMarkets();
      return await Promise.all(
        markets.map(async (m) => {
          const ibToken = CErc20__factory.connect(m, provider);
          const underlyingAddress = await ibToken.underlying();
          const underlying = Erc20__factory.connect(
            underlyingAddress,
            provider
          );
          const symbol = await underlying.symbol();
          return {
            ibTokenAddress: m,
            underlyingSymbol: symbol,
          };
        })
      );
    },
    {
      enabled: !!comptroller,
    }
  );

  return (
    <section>
      <Button
        disabled={connecting}
        onClick={() => {
          wallet ? disconnect(wallet) : connect();
        }}
      >
        {connecting
          ? 'connecting'
          : wallet
          ? 'disconnect wallet'
          : 'connect wallet'}
      </Button>
      {network && <h1>Connected Chain: {network.id.toUpperCase()}</h1>}
      {wallet?.accounts[0] && (
        <h1>Connected Address: {wallet.accounts[0].address}</h1>
      )}
      {marketData && network && (
        <MarketForm
          markets={marketData}
          network={network}
          initialValues={{ mint: [], borrow: [], flashloan: [] } as FormValue}
          onSubmit={async (values) => {
            if (!network || !provider) return;

            const multiSendData = hexConcat([
              buildMultiSendMintPauseTx(network, values.mint),
              buildMultiSendBorrowPauseTx(network, values.borrow),
              buildMultiSendFlashloanPauseTx(network, values.flashloan),
            ]);

            try {
              await gnosisMultiSend(
                network,
                provider.getSigner(),
                multiSendData
              );
            } catch (e) {
              alert((e as Error).message);
            }
          }}
        />
      )}
    </section>
  );
};

interface MarketFormProps extends FormikConfig<FormValue> {
  markets: Market[];
  network: Network;
}

const MarketForm = ({
  markets,
  network,
  ...props
}: MarketFormProps): JSX.Element => {
  const valid = (values: FormValue) => {
    return values.mint.length !== 0 || values.borrow.length !== 0;
  };

  return (
    <Formik {...props}>
      {({ handleChange, values, setFieldValue, handleReset, isSubmitting }) => (
        <Form>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Symbol</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>
                    Pause Mint
                    <MarketFormHeaderCheckBox markets={markets} k="mint" />
                  </TableCell>
                  <TableCell>
                    Pause Borrow
                    <MarketFormHeaderCheckBox markets={markets} k="borrow" />
                  </TableCell>
                  <TableCell>
                    Pause FL
                    <MarketFormHeaderCheckBox markets={markets} k="flashloan" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {markets.map((d) => (
                  <TableRow
                    key={d.ibTokenAddress}
                    sx={{
                      '&:hover': {
                        backgroundColor: 'action.hover',
                      },
                    }}
                  >
                    <TableCell>{d.underlyingSymbol}</TableCell>
                    <TableCell>
                      <AddressDisplay
                        address={d.ibTokenAddress}
                        network={network.id}
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        name="mint"
                        value={d.ibTokenAddress}
                        onChange={handleChange}
                        checked={values.mint.includes(d.ibTokenAddress)}
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        name="borrow"
                        value={d.ibTokenAddress}
                        onChange={handleChange}
                        checked={values.borrow.includes(d.ibTokenAddress)}
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        name="flashloan"
                        value={d.ibTokenAddress}
                        onChange={handleChange}
                        checked={values.flashloan.includes(d.ibTokenAddress)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Stack direction="row">
              <Button
                onClick={() => {
                  for (const k in values) {
                    setFieldValue(
                      k,
                      markets.map((m) => m.ibTokenAddress)
                    );
                  }
                }}
              >
                Select All
              </Button>
              <Button onClick={() => handleReset()}>Deselect All</Button>
            </Stack>
            <Button
              variant="contained"
              type="submit"
              disabled={isSubmitting || !valid(values)}
            >
              Send Tx
            </Button>
          </TableContainer>
        </Form>
      )}
    </Formik>
  );
};

interface MarketFormHeaderCheckBoxProps {
  markets: Market[];
  k: keyof FormValue;
}

const MarketFormHeaderCheckBox = ({
  markets,
  k,
}: MarketFormHeaderCheckBoxProps): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<FormValue>();
  return (
    <Checkbox
      checked={values[k].length === markets.length}
      indeterminate={values[k].length > 0 && values[k].length < markets.length}
      onChange={(e) => {
        if (e.target.checked) {
          setFieldValue(
            k,
            markets.map((d) => d.ibTokenAddress)
          );
        } else {
          setFieldValue(k, []);
        }
      }}
    />
  );
};

// See https://etherscan.io/address/0x40A2aCCbd92BCA938b02010E17A5b8929b49130D#code
function encodeMultiSendTx(address: BytesLike, data: BytesLike) {
  const operation = '0x00';
  const value = ethers.constants.HashZero;
  const dataLength = hexZeroPad(hexValue(hexDataLength(data)), 32);

  return hexConcat([operation, address, value, dataLength, data]);
}

function buildMultiSendBorrowPauseTx(network: Network, markets: string[]) {
  const ci = Comptroller__factory.createInterface();

  const borrowPauseTxs = markets.map((m) => {
    const functionData = ci.encodeFunctionData('_setBorrowPaused', [m, true]);
    return encodeMultiSendTx(network.unitrollerAddress, functionData);
  });
  return hexConcat(borrowPauseTxs);
}

function buildMultiSendMintPauseTx(network: Network, markets: string[]) {
  const ci = Comptroller__factory.createInterface();

  const borrowPauseTxs = markets.map((m) => {
    const functionData = ci.encodeFunctionData('_setMintPaused', [m, true]);
    return encodeMultiSendTx(network.unitrollerAddress, functionData);
  });
  return hexConcat(borrowPauseTxs);
}

function buildMultiSendFlashloanPauseTx(network: Network, markets: string[]) {
  const ci = Comptroller__factory.createInterface();

  const borrowPauseTxs = markets.map((m) => {
    const functionData = ci.encodeFunctionData('_setFlashloanPaused', [
      m,
      true,
    ]);
    return encodeMultiSendTx(network.unitrollerAddress, functionData);
  });
  return hexConcat(borrowPauseTxs);
}

async function gnosisMultiSend(
  network: Network,
  signer: Signer,
  multiSendData: string
) {
  const multiSend = MultiSendCallOnly__factory.createInterface();
  const multiSendFunctionData = multiSend.encodeFunctionData('multiSend', [
    multiSendData,
  ]);

  const signerAddress = await signer.getAddress();

  const r = ethers.utils.hexZeroPad(signerAddress, 32);
  const s = ethers.constants.HashZero;
  const v = '0x01';
  const signatures = ethers.utils.hexConcat([r, s, v]);
  const safe = GnosisSafe__factory.connect(network.guardianSafeAddress, signer);

  // console.log('md', multiSendData);
  // console.log('s', signatures);

  await safe.execTransaction(
    network.multisendAddress, // to
    0, // value
    multiSendFunctionData, // data
    1, // operation (delegate call)
    0, // safeTxGas
    0, // baseGas
    0, // gasPrice
    ethers.constants.AddressZero, // gasToken
    ethers.constants.AddressZero, // refundReceiver
    signatures
  );
}

export default EmergencyPage;
