import { useState } from 'react';

const useTabs = () => {
  const [tab, setTab] = useState<number>(0);

  const getA11yProps = (index: number) => {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  return {
    tab,
    handleChange,
    getA11yProps,
  };
};

export default useTabs;
