import { Protocol } from 'types';

export type AlphaContract = {
  underlyingAddress: string;
  safeboxAddress: string;
  address: string;
};

export const CreditLimitUsageCharts = [
  {
    network: Protocol.Ethereum,
    account: '0xba5eBAf3fc1Fcca67147050Bf80462393814E54B',
    markets: [
      '0x41c84c0e2ee0b740cf0d31f63f3b6f627dc6b393',
      '0x48759f220ed983db51fa7a8c0d2aab8f3ce4166a',
      '0x8e595470ed749b85c6f7669de83eae304c2ec68f',
      '0x76eb2fe28b36b3ee97f3adae0c69606eedb2a37c',
    ],
  },
  {
    network: Protocol.Optimism,
    account: '0xffa51a5ec855f8e38dd867ba503c454d8bbc5ab9',
    markets: [
      '0x4645e0952678e9566fb529d9313f5730e4e1c412',
      '0x049e04bee77cffb055f733a138a2f204d3750283',
      '0x874c01c2d1767efa01fa54b2ac16be96fad5a742',
      '0x1d073cf59ae0c169cbc58b6fdd518822ae89173a',
      '0x17533a1bde957979e3977ebbfbc31e6deeb25c7d',
    ],
  },
  {
    network: Protocol.Avalanche,
    account: '0x376d16C7dE138B01455a51dA79AD65806E9cd694',
    markets: [
      '0x02c9133627a14214879175a7a222d0a7f7404efb',
      '0x085682716f61a72bf8c573fbaf88cca68c60e99b',
      '0x18931772adb90e7f214b6cbc78ddd6e0f090d4b1',
      '0x338eee1f7b89ce6272f302bdc4b952c13b221f1d',
      '0x3af7c11d112c1c730e5cee339ca5b48f9309acbc',
      '0xb09b75916c5f4097c8b5812e63e216fef97661fc',
      '0xb3c68d69e95b095ab4b33b4cb67dbc0fbf3edf56',
      '0xbf1430d9ec170b7e97223c7f321782471c587b29',
      '0xceb1ce674f38398432d20bc8f90345e91ef46fd3',
      '0xe28965073c49a02923882b8329d3e8c1d805e832',
      '0xec5aa19566aa442c8c50f3c6734b6bb23ff21cd7',
    ],
  },
  {
    network: Protocol.Fantom,
    account: '0x060E91A44f16DFcc1e2c427A0383596e1D2e886f',
    markets: [
      '0x04c762a5df2fa02fe868f25359e0c259fb811cfe',
      '0x20ca53e2395fa571798623f1cfbd11fe2c114c24',
      '0x328a7b4d538a2b3942653a9983fda3c12c571141',
      '0x46f298d5bb6389ccb6c1366bb0c8a30892ca2f7c',
      '0x4eceddf62277ed78623f9a94995c680f8fd6c00e',
      '0x70fac71debfd67394d1278d98a29dea79dc6e57a',
      '0xcc3e89fbc10e155f1164f8c9cf0703acde53f6fd',
      '0xd528697008ac67a21818751a5e3c58c8dae54696',
    ],
  },
];

export const ChartAddresses: string[] = [
  '0x41c84c0e2ee0b740cf0d31f63f3b6f627dc6b393',
  '0x48759f220ed983db51fa7a8c0d2aab8f3ce4166a',
  '0x8e595470ed749b85c6f7669de83eae304c2ec68f',
  '0x76eb2fe28b36b3ee97f3adae0c69606eedb2a37c',
];

export const AlphaLendingContracts: Array<AlphaContract> = [
  {
    underlyingAddress: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    safeboxAddress: '0xeea3311250fe4c3268f8e684f7c87a82ff183ec1',
    address: '0x41c84c0e2ee0b740cf0d31f63f3b6f627dc6b393',
  },
  {
    underlyingAddress: '0x6b175474e89094c44da98b954eedeac495271d0f',
    safeboxAddress: '0xee8389d235e092b2945fe363e97cdbed121a0439',
    address: '0x8e595470ed749b85c6f7669de83eae304c2ec68f',
  },
  {
    underlyingAddress: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    safeboxAddress: '0x020edc614187f9937a1efeee007656c6356fb13a',
    address: '0x48759f220ed983db51fa7a8c0d2aab8f3ce4166a',
  },
  {
    underlyingAddress: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    safeboxAddress: '0x08bd64bfc832f1c2b3e07e634934453ba7fa2db2',
    address: '0x76eb2fe28b36b3ee97f3adae0c69606eedb2a37c',
  },
  {
    underlyingAddress: '0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e',
    safeboxAddress: '0xe52557bf7315fd5b38ac0ff61304cb33bb973603',
    address: '0xfa3472f7319477c9bfecdd66e4b948569e7621b9',
  },
  {
    underlyingAddress: '0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f',
    safeboxAddress: '0x4d38b1ac1fad488e22282db451613edd10434bdc',
    address: '0x12a9cc33a980daa74e00cc2d1a0e74c57a93d12c',
  },
  {
    underlyingAddress: '0x57ab1ec28d129707052df4df418d58a2d46d5f51',
    safeboxAddress: '0x8897ca3e1b9bc5d5d715b653f186cc7767bd4c66',
    address: '0xa7c4054afd3dbbbf5bfe80f41862b89ea05c9806',
  },
  {
    underlyingAddress: '0x1494ca1f11d487c2bbe4543e90080aeba4ba3c2b',
    safeboxAddress: '0xd80ce6816f263c3ca551558b2034b61bc9852b97',
    address: '0x7736ffb07104c0c400bb0cc9a7c228452a732992',
  },
  {
    underlyingAddress: '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984',
    safeboxAddress: '0x6cdd8cbcffb3821be459f6fcac647a447e56c999',
    address: '0xfeeb92386a055e2ef7c2b598c872a4047a7db59f',
  },
  {
    underlyingAddress: '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2',
    safeboxAddress: '0x2abba23bdc48245f5f68661e390da243755b569f',
    address: '0x226f3738238932ba0db2319a8117d9555446102f',
  },
  {
    underlyingAddress: '0x514910771af9ca656af840dff83e8264ecf986ca',
    safeboxAddress: '0xb59ecdf6c2aea5e67fafbaf912b26658d43295ed',
    address: '0xe7bff2da8a2f619c2586fb83938fa56ce803aa16',
  },
  {
    underlyingAddress: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
    safeboxAddress: '0xe520679df7e32600d9b2caf50bd5a4337ea3cf89',
    address: '0x8fc8bfd80d6a9f17fb98a373023d72531792b431',
  },
];
