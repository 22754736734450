import { useCallback, useMemo } from 'react';
import { useQuery, useQueries } from '@tanstack/react-query';
import { BigNumber } from 'ethers';
import { map, filter, find, reduce, uniqBy } from 'lodash';
import { request, gql } from 'graphql-request';
import { Market } from 'hooks/useMarkets';
import useComptroller from 'hooks/useComptroller';
import { useMulticall } from 'hooks/useMultiCall';
import { CToken__factory } from 'contracts/types/factories/CToken__factory';
import { Protocol } from 'types';
import { getNetworkEndpoint, isSameAddress } from 'helpers';

export type CreditLimit = {
  id: string;
  borrower: string;
  market: Market;
  isListed: boolean;
  creditLimit: BigNumber | null;
  borrowBalance: BigNumber | null;
};

type CreditLimitRaw = {
  id: string;
  borrower: string;
  market: Market;
};

const queryString = gql`
  query {
    creditLimits {
      id
      borrower
      market {
        address
        underlyingSymbol
        underlyingAddress
        underlyingDecimals
      }
    }
  }
`;

function useCreditLimits(network: Protocol) {
  const { address: comptrollerAddress, abi: comptrollerABI } =
    useComptroller(network);
  const { multiCalls, buildCall } = useMulticall(network);

  const {
    data: borrowers,
    isLoading,
    isFetching,
  } = useQuery<Array<CreditLimitRaw>>(
    ['borrowers', network],
    async (): Promise<CreditLimitRaw[]> => {
      const { creditLimits } = await request(
        getNetworkEndpoint(network),
        queryString
      );
      return creditLimits;
    }
  );

  const fetchCreditLimits = useCallback(async (): Promise<
    Array<{ borrower: string; address: string; creditLimit: BigNumber }>
  > => {
    if (!borrowers) {
      return [];
    }

    const credits = await multiCalls(
      map(borrowers, ({ borrower, market }) =>
        buildCall(
          comptrollerAddress,
          comptrollerABI,
          'creditLimits(address,address)',
          [borrower, market.address]
        )
      )
    );

    if (!credits) {
      return [];
    }

    return map(borrowers, ({ borrower, market }, index) => {
      return {
        borrower,
        address: market.address,
        creditLimit: credits[index][0],
      };
    });
  }, [borrowers, multiCalls, buildCall, comptrollerAddress, comptrollerABI]);

  const fetchBorrowBalances = useCallback(async (): Promise<
    Array<{ borrower: string; address: string; borrowBalance: BigNumber }>
  > => {
    if (!borrowers) {
      return [];
    }

    const balances = await multiCalls(
      map(borrowers, ({ borrower, market }) =>
        buildCall(market.address, CToken__factory.abi, 'borrowBalanceStored', [
          borrower,
        ])
      )
    );

    if (!balances) {
      return [];
    }

    return map(borrowers, ({ borrower, market }, index) => {
      return {
        borrower,
        address: market.address,
        borrowBalance: balances[index][0],
      };
    });
  }, [borrowers, buildCall, multiCalls]);

  const fetchListedMarkets = useCallback(async (): Promise<
    Array<{
      symbol: string;
      address: string;
      isListed: boolean;
    }>
  > => {
    if (!borrowers) {
      return [];
    }

    const cTokenAddresses = uniqBy(
      map(borrowers, ({ market }) => ({
        address: market.address,
        symbol: market.underlyingSymbol,
      })),
      'address'
    );

    const listedMarkets = await multiCalls(
      map(cTokenAddresses, ({ address }) =>
        buildCall(comptrollerAddress, comptrollerABI, 'isMarketListed', [
          address,
        ])
      )
    );

    if (!listedMarkets) {
      return [];
    }

    return map(cTokenAddresses, (market, index) => {
      return {
        ...market,
        isListed: listedMarkets[index][0],
      };
    });
  }, [borrowers, buildCall, comptrollerABI, comptrollerAddress, multiCalls]);

  const [
    { data: creditLimits },
    { data: borrowBalances },
    { data: listedMarkets },
  ] = useQueries({
    queries: [
      {
        queryKey: ['credit-limits', network],
        queryFn: fetchCreditLimits,
        enabled: !!borrowers,
        initialData: [],
      },
      {
        queryKey: ['borrow-balances', network],
        queryFn: fetchBorrowBalances,
        enabled: !!borrowers,
        initialData: [],
      },
      {
        queryKey: ['listed-market', network],
        queryFn: fetchListedMarkets,
        enabled: !!borrowers,
        initialData: [],
      },
    ],
  });

  const networkCreditLimits = useMemo<CreditLimit[]>(() => {
    if (!borrowers) {
      return [];
    }

    return reduce(
      borrowers,
      (acc: CreditLimit[], { id, market, borrower }) => {
        const creditData = find(
          creditLimits,
          (credit) =>
            isSameAddress(credit.borrower, borrower) &&
            isSameAddress(credit.address, market.address)
        );
        const balanceData = find(
          borrowBalances,
          (balance) =>
            isSameAddress(balance.borrower, borrower) &&
            isSameAddress(balance.address, market.address)
        );

        const listingData = find(listedMarkets, ({ address }) =>
          isSameAddress(address, market.address)
        );

        return acc.concat({
          id,
          borrower,
          market,
          isListed: listingData ? listingData.isListed : false,
          creditLimit: creditData ? creditData.creditLimit : null,
          borrowBalance: balanceData ? balanceData.borrowBalance : null,
        });
      },
      []
    );
  }, [borrowers, creditLimits, borrowBalances, listedMarkets]);

  const validCreditLimits = useMemo<CreditLimit[]>(() => {
    return networkCreditLimits
      ? filter(
          networkCreditLimits,
          ({ creditLimit, isListed }) =>
            isListed && creditLimit !== null && creditLimit.gt(0)
        )
      : [];
  }, [networkCreditLimits]);

  const expiredCreditLimits = useMemo<CreditLimit[]>(() => {
    return networkCreditLimits
      ? filter(
          networkCreditLimits,
          ({ creditLimit, isListed }) =>
            !isListed ||
            creditLimit === null ||
            (creditLimit && creditLimit.lte(0))
        )
      : [];
  }, [networkCreditLimits]);

  return {
    creditLimits: validCreditLimits,
    expiredCreditLimits,
    isLoading,
    isFetching,
  };
}

export default useCreditLimits;
