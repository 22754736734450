import { createTheme } from '@mui/material/styles';
import { blue, grey, yellow, green } from '@mui/material/colors';

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string;
    };
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }

  interface Palette {
    highlight: Palette['primary'];
  }

  interface PaletteOptions {
    highlight?: PaletteOptions['primary'];
    myBackground?: PaletteOptions['primary'];
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: blue[600],
    },
    secondary: {
      main: yellow[600],
    },
    highlight: {
      main: grey[600],
    },
    myBackground: {
      main: green[200],
    },
  },
});
