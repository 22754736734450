import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
} from '@mui/material';
import useUser from 'hooks/useUser';

export const UserCard = () => {
  const { user } = useUser();

  return (
    <List>
      {user && (
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar alt="" src={user.photoURL} />
          </ListItemAvatar>
          <ListItemText
            primary={user.name}
            secondary={
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {user.email}
              </Typography>
            }
          />
        </ListItem>
      )}
    </List>
  );
};

export default UserCard;
