import { createContext, ReactNode, useState } from 'react';

interface SessionProviderProps {
  children: ReactNode;
}

export type Session = {
  isAuthenticated?: boolean;
  user: User | null;
};

export type User = {
  name: string;
  email: string;
  photoURL: string;
};

export const defaultSession: Session = {
  isAuthenticated: false,
  user: null,
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const EmptyMethod = () => {};

export const SessionContext = createContext<{
  session: Session;
  updateSession: (session: Session) => void;
}>({
  session: defaultSession,
  updateSession: EmptyMethod,
});

export const SessionProvider = ({ children }: SessionProviderProps) => {
  const [sessionState, setSessionState] = useState<Session>(defaultSession);
  const updateSession = (session: Session) => setSessionState(session);

  return (
    <SessionContext.Provider
      value={{
        session: sessionState,
        updateSession,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
