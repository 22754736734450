import { Card, CardHeader, CardContent } from '@mui/material';
import BN from 'bignumber.js';
import { useIBFFData, ForexData } from 'hooks/useIBFFData';
import SortableTable, { SortedTableColumn } from 'components/SortableTable';

interface IBFFColumn extends SortedTableColumn {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render?: (row: ForexData) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sorting?: (row: ForexData) => any;
}

export const IBFFPage = () => {
  const { data, isLoading } = useIBFFData();
  const columns: IBFFColumn[] = [
    {
      id: 'name',
      label: 'Symbol',
      align: 'left',
      sortable: true,
    },
    {
      id: 'borrowedValueInUsdc',
      label: 'Borrow Value (USDC)',
      align: 'right',
      sortable: true,
      render: ({ borrowedValueInUsdc }) =>
        new BN(borrowedValueInUsdc).toFormat(2),
      sorting: ({ borrowedValueInUsdc }) =>
        new BN(borrowedValueInUsdc).toNumber(),
    },
    {
      id: 'claimableProfitInUsdc',
      label: 'Claimable Profit (USDC)',
      align: 'right',
      sortable: true,
      render: ({ claimableProfitInUsdc }) =>
        new BN(claimableProfitInUsdc).toFormat(2),
      sorting: ({ claimableProfitInUsdc }) =>
        new BN(claimableProfitInUsdc).toNumber(),
    },
    {
      id: 'holdingsValueInUsdc',
      label: 'Holdings Value (USDC)',
      align: 'right',
      sortable: true,
      render: ({ holdingsValueInUsdc }) =>
        new BN(holdingsValueInUsdc).toFormat(2),
      sorting: ({ holdingsValueInUsdc }) =>
        new BN(holdingsValueInUsdc).toNumber(),
    },
    {
      id: 'Profit',
      label: 'Profit Value',
      align: 'right',
      sortable: true,
      render: ({
        claimableProfitInUsdc,
        holdingsValueInUsdc,
        borrowedValueInUsdc,
      }) =>
        new BN(claimableProfitInUsdc)
          .plus(holdingsValueInUsdc)
          .minus(borrowedValueInUsdc)
          .toFormat(2),
      sorting: ({
        claimableProfitInUsdc,
        borrowedValueInUsdc,
        holdingsValueInUsdc,
      }) =>
        new BN(claimableProfitInUsdc)
          .plus(holdingsValueInUsdc)
          .minus(borrowedValueInUsdc)
          .toNumber(),
    },
    {
      id: 'net',
      label: 'Credit Usage',
      align: 'right',
      sortable: true,
      render: ({
        claimableProfitInUsdc,
        borrowedValueInUsdc,
        holdingsValueInUsdc,
      }) =>
        `${new BN(claimableProfitInUsdc)
          .plus(holdingsValueInUsdc)
          .dividedBy(borrowedValueInUsdc)
          .shiftedBy(2)
          .toFormat(2)}%`,
      sorting: ({
        claimableProfitInUsdc,
        borrowedValueInUsdc,
        holdingsValueInUsdc,
      }) => {
        if (new BN(borrowedValueInUsdc).isZero()) {
          return -1;
        }
        return new BN(claimableProfitInUsdc)
          .plus(holdingsValueInUsdc)
          .dividedBy(borrowedValueInUsdc)
          .toNumber();
      },
    },
  ];

  return (
    <section>
      <Card>
        <CardHeader>IBFF Borrowers</CardHeader>
        <CardContent>
          <SortableTable
            title="IBFF Borrowers"
            columns={columns}
            dataItems={data}
            isLoading={isLoading}
            defaultOrderKey="name"
          />
        </CardContent>
      </Card>
    </section>
  );
};

export default IBFFPage;
