import { useMemo } from 'react';
import { Card, CardHeader, CardContent, Typography, Grid } from '@mui/material';
import { map, find, reduce, filter, includes } from 'lodash';
import { BigNumber } from 'ethers';
import { useQueries } from '@tanstack/react-query';
import BN from 'bignumber.js';
import useMultiCall from 'hooks/useMultiCall';
import { useMarkets } from 'hooks/useMarkets';
import { useBalances } from 'hooks/useBalances';
import { Protocol } from 'types';
import {
  AlphaLendingContracts,
  ChartAddresses,
  CreditLimitUsageCharts,
} from './constants';
import { AccountCreditLimitChart } from 'components/AccountCreditLimitChart';
import { AlphaChart } from './AlphaChart';
import { CErc20__factory } from 'contracts/types/factories/CErc20__factory';
import SortableTable, { SortedTableColumn } from 'components/SortableTable';
import { AddressDisplay } from 'components/AddressDisplay';
import { isSameAddress } from 'helpers';

export type AlphaData = {
  address: string;
  name: string;
  symbol: string;
  underlyingAddress: string;
  underlyingName: string;
  underlyingSymbol: string;
  underlyingDecimals: number;
  lendingAmount: BN;
  borrowAmount: BN;
  oldBorrowAmount: BN;
  totalBorrow: BN;
};

interface AlphaColumn extends SortedTableColumn {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render?: (row: AlphaData) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sorting?: (row: AlphaData) => any;
}

const getLendingAmount = (
  balance: BigNumber,
  exchangeRate: BigNumber,
  underlyingDecimal: number
): BN => {
  return new BN(balance.toString())
    .multipliedBy(new BN(exchangeRate.toString()))
    .shiftedBy(-1 * (36 - (18 - underlyingDecimal)));
};

const AlphaAddress = '0xba5eBAf3fc1Fcca67147050Bf80462393814E54B';
const OldAlphaAddress = '0x560A8E3B79d23b0A525E15C6F3486c6A293DDAd2';

function AlphaHomoraPage() {
  const { multiCalls, buildCall } = useMultiCall(Protocol.Ethereum);
  const { listed: markets } = useMarkets(Protocol.Ethereum);
  const { marketBalances } = useBalances(Protocol.Ethereum, AlphaAddress);
  const { marketBalances: oldMarketBalances } = useBalances(
    Protocol.Ethereum,
    OldAlphaAddress
  );
  const lendingContacts = AlphaLendingContracts;

  const [
    { data: lendings, isLoading: balancesLoading },
    { data: exchangeRates, isLoading: exchangeRatesLoading },
  ] = useQueries({
    queries: [
      {
        queryKey: ['alpha-lendings'],
        queryFn: async (): Promise<
          Array<{ address: string; amount: BigNumber }>
        > => {
          const calls = map(lendingContacts, ({ address, safeboxAddress }) =>
            buildCall(address, CErc20__factory.abi, 'balanceOf', [
              safeboxAddress,
            ])
          );
          const lendings = await multiCalls(calls);
          return map(lendingContacts, ({ address }, index) => {
            return {
              address,
              amount: lendings[index][0],
            };
          });
        },
      },
      {
        queryKey: ['alpha-exchange-rates'],
        queryFn: async (): Promise<
          Array<{ address: string; exchangeRate: BigNumber }>
        > => {
          const exchangeRates = await multiCalls(
            map(lendingContacts, ({ address }) =>
              buildCall(address, CErc20__factory.abi, 'exchangeRateStored', [])
            )
          );

          return map(lendingContacts, ({ address }, index) => ({
            address,
            exchangeRate: exchangeRates[index][0],
          }));
        },
      },
    ],
  });

  const alphaData = useMemo<AlphaData[]>(
    () =>
      reduce(
        markets,
        (
          acc: AlphaData[],
          {
            address,
            name,
            symbol,
            underlyingAddress,
            underlyingDecimals,
            underlyingName,
            underlyingSymbol,
          }
        ) => {
          const lendingData = find(lendings, (lending) =>
            isSameAddress(lending.address, address)
          );

          const exchangeRateData = find(exchangeRates, (exchangeRate) =>
            isSameAddress(exchangeRate.address, address)
          );

          const borrowData = find(marketBalances, (marketBorrow) =>
            isSameAddress(marketBorrow.address, address)
          );

          const oldBorrowData = find(oldMarketBalances, (marketBorrow) =>
            isSameAddress(marketBorrow.address, address)
          );

          if (!lendingData || !exchangeRateData) {
            return acc;
          }

          const lendingAmount = getLendingAmount(
            lendingData.amount,
            exchangeRateData.exchangeRate,
            underlyingDecimals
          );
          const borrowAmount =
            borrowData && borrowData.balance
              ? new BN(borrowData.balance.toString()).shiftedBy(
                  -1 * underlyingDecimals
                )
              : new BN(0);

          const oldBorrowAmount =
            oldBorrowData && oldBorrowData.balance
              ? new BN(oldBorrowData.balance.toString()).shiftedBy(
                  -1 * underlyingDecimals
                )
              : new BN(0);

          return acc.concat({
            address,
            name,
            symbol,
            underlyingAddress,
            underlyingSymbol,
            underlyingName,
            underlyingDecimals,
            lendingAmount,
            borrowAmount,
            oldBorrowAmount,
            totalBorrow: borrowAmount.plus(oldBorrowAmount),
          });
        },
        []
      ),
    [markets, lendings, exchangeRates, marketBalances, oldMarketBalances]
  );

  const columns: AlphaColumn[] = [
    {
      id: 'underlyingSymbol',
      label: 'Symbol',
      align: 'left',
      sortable: true,
    },
    {
      id: 'lendingAmount',
      label: 'Lending Amount',
      align: 'right',
      sortable: true,
      render: ({ lendingAmount }) => lendingAmount.toFormat(6),
    },
    {
      id: 'borrowAmount',
      label: (
        <span>
          Borrow Amount{' '}
          <AddressDisplay network={Protocol.Ethereum} address={AlphaAddress} />
        </span>
      ),
      align: 'right',
      sortable: true,
      sorting: ({ borrowAmount }) => borrowAmount.toNumber(),
      render: ({ borrowAmount }) => borrowAmount.toFormat(6),
    },
    {
      id: 'oldBorrowAmount',
      label: (
        <div>
          Borrow Amount{' '}
          <AddressDisplay
            network={Protocol.Ethereum}
            address={OldAlphaAddress}
          />
        </div>
      ),
      align: 'right',
      sortable: true,
      sorting: ({ oldBorrowAmount }) => oldBorrowAmount.toNumber(),
      render: ({ oldBorrowAmount }) => oldBorrowAmount.toFormat(6),
    },
    {
      id: 'totalAmount',
      label: 'Total Borrow',
      align: 'right',
      sortable: true,
      sorting: ({ totalBorrow }) => totalBorrow.toNumber(),
      render: ({ totalBorrow }) => totalBorrow.toFormat(6),
    },
    {
      id: 'percentage',
      label: 'Total Borrow / Lending %',
      align: 'right',
      sortable: true,
      sorting: ({ totalBorrow, lendingAmount }) =>
        totalBorrow.dividedBy(lendingAmount).toNumber(),
      render: ({ totalBorrow, lendingAmount }) => {
        if (!lendingAmount || lendingAmount.isZero()) {
          return '--';
        }

        return `${totalBorrow
          .dividedBy(lendingAmount)
          .shiftedBy(2)
          .toFormat(2)}%`;
      },
    },
  ];

  return (
    <section>
      <Typography variant="h4">Alpha Homora</Typography>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Credit Limit Usages" />
            <CardContent>
              <Grid container spacing={1}>
                {map(
                  CreditLimitUsageCharts,
                  ({ network, account, markets }) => (
                    <Grid key={network + account} item xs={12} xl={6}>
                      <AccountCreditLimitChart
                        account={account}
                        network={network}
                        markets={markets}
                      />
                    </Grid>
                  )
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <AlphaChart
            alphaData={filter(alphaData, ({ address }) =>
              includes(ChartAddresses, address.toLowerCase())
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <SortableTable
                isLoading={balancesLoading || exchangeRatesLoading}
                columns={columns}
                dataItems={map(alphaData, (data) => ({
                  ...data,
                  id: data.address,
                }))}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </section>
  );
}

export default AlphaHomoraPage;
