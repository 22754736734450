import { createContext, ReactNode, useContext, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { SessionContext } from 'providers/SessionProvider';

interface FirebaseContextProps {
  children: ReactNode;
}

const firebaseConfig = {
  apiKey: 'AIzaSyDzCcBsYCgozWBKSE-bHG9bZ4S1VdmnkHA',
  authDomain: 'ib-dashboard-b8837.firebaseapp.com',
  projectId: 'ib-dashboard-b8837',
  storageBucket: 'ib-dashboard-b8837.appspot.com',
  messagingSenderId: '82274689004',
  appId: '1:82274689004:web:5e51b5c3ac4c888185aac9',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export const defaultClients = {
  firebaseDB: getFirestore(app),
  auth,
};

export const FirebaseContext = createContext(defaultClients);

export function FirebaseProvider({ children }: FirebaseContextProps) {
  const { session, updateSession } = useContext(SessionContext);
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        const ibUser = {
          name: user.displayName || '',
          email: user.email || '',
          photoURL: user.photoURL || '',
        };

        if (session.isAuthenticated) {
          return;
        }
        updateSession({
          isAuthenticated: true,
          user: ibUser,
        });
      } else {
        if (session.isAuthenticated) {
          updateSession({ isAuthenticated: false, user: null });
        }
      }
    });
  }, [session, updateSession]);
  return (
    <FirebaseContext.Provider value={defaultClients}>
      {children}
    </FirebaseContext.Provider>
  );
}

export default FirebaseProvider;
