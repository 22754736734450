import { map } from 'lodash';
import {
  TableHead,
  TableRow,
  TableSortLabel,
  TableCell,
  Box,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { OrderType, SortedTableColumn } from './index';

interface SortableTableHeadProps<T> {
  columns: T[];
  order: OrderType;
  orderBy: string;
  isExpandable: boolean;
  onSort: (id: string) => void;
}

function SortableTableHead<T extends SortedTableColumn>(
  props: SortableTableHeadProps<T>
): JSX.Element {
  const { columns, order, orderBy, isExpandable, onSort } = props;
  return (
    <TableHead>
      <TableRow>
        {isExpandable && <TableCell></TableCell>}
        {map(columns, (column) => (
          <TableCell
            key={column.id}
            sortDirection={orderBy === column.id ? order : false}
            align={column.align}
            sx={{
              minWidth: 120,
              ...column.sx,
            }}
          >
            <TableSortLabel
              disabled={!column.sortable}
              active={orderBy === column.id}
              direction={orderBy === column.id ? order : OrderType.Ascending}
              onClick={() => column.sortable && onSort(column.id)}
            >
              {column.label}
              {orderBy === column.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default SortableTableHead;
