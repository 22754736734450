import { useCallback, useMemo } from 'react';
import { BigNumber, utils } from 'ethers';
import BN from 'bignumber.js';
import { useQueries } from '@tanstack/react-query';
import { request, gql } from 'graphql-request';
import { filter, includes, toLower, map, isEmpty, find } from 'lodash';
import useComptroller from 'hooks/useComptroller';
import { useMulticall } from 'hooks/useMultiCall';
import { CToken__factory } from 'contracts/types/factories/CToken__factory';
import { EthereumPriceOracle__factory } from 'contracts/types/factories/EthereumPriceOracle__factory';
import { PriceOracle__factory } from 'contracts/types/factories/PriceOracle__factory';
import {
  isSameAddress,
  getUnderlyingFromCToken,
  getUsdFromUnderlying,
  getUnderlyingPriceFromUsdPrice,
} from 'helpers';
import { Protocol } from 'types';
import { Networks } from 'configurations';
import { CTokenDecimal } from 'constants/CToken';

export const queryString = gql`
  query {
    markets {
      id
      address
      symbol
      name
      implementation
      flashloanPaused
      underlyingName
      underlyingSymbol
      underlyingAddress
      underlyingDecimals
      interestRateModelAddress
      collateralFactor
      reserveFactor
      borrowPaused
      supplyPaused
      delisted
    }
  }
`;

export interface QueryMarket {
  id: string;
  address: string;
  symbol: string;
  name: string;
  implementation: string;
  flashloanPaused: boolean;
  underlyingName: string;
  underlyingSymbol: string;
  underlyingAddress: string;
  underlyingDecimals: number;
  interestRateModelAddress: string;
  collateralFactor: string;
  reserveFactor: string;
  borrowPaused: boolean;
  supplyPaused: boolean;
  delisted: boolean;
}

export type Market = {
  id: string;
  address: string;
  symbol: string;
  name: string;
  implementation: string;
  flashloanPaused: boolean;
  underlyingName: string;
  underlyingSymbol: string;
  underlyingAddress: string;
  underlyingDecimals: number;
  underlyingPrice?: BN;
  interestRateModelAddress: string;
  collateralFactor?: BN;
  reserveFactor?: BN;
  collateralTokens?: BN;
  collateralCap: {
    cToken: string;
    underlying: string;
    usd: string;
  };
  collateralUsage: number | null;
  borrowCap: {
    underlying: string;
    usd: string;
  };
  totalBorrow: {
    underlying: string;
    usd: string;
  };
  borrowUsage: number | null;
  borrowPaused: boolean;
  supplyPaused: boolean;
  delisted: boolean;
};

const getCollateralUsage = (
  collateralToken: BN | undefined,
  collateralCap: BN | undefined
): number | null => {
  if (!collateralCap || collateralCap.isZero()) {
    return null;
  }

  if (!collateralToken || collateralToken.isZero()) {
    return 0;
  }

  return collateralToken.dividedBy(collateralCap).shiftedBy(2).dp(2).toNumber();
};

export function useMarkets(network: Protocol) {
  const {
    comptroller,
    address: comptrollerAddress,
    abi: comptrollerABI,
  } = useComptroller(network);
  const { multiCalls, buildCall } = useMulticall(network);

  const fetchGraphMarkets = useCallback(async (): Promise<QueryMarket[]> => {
    const { markets } = await request(Networks[network].subgraph, queryString);
    return markets || [];
  }, [network]);

  const [
    { data: allMarkets, isFetching: isAllMarketsFetching, isSuccess },
    { data: subGraphData, isFetching: isSubFetching, isSuccess: isSubSuccess },
    {
      data: softDelistedAddresses,
      isFetching: isSoftDelistedAddressesFetching,
    },
    { data: oracleAddress },
  ] = useQueries({
    queries: [
      {
        queryKey: ['all-markets', network],
        queryFn: async (): Promise<string[]> =>
          await comptroller.getAllMarkets(),
        initialData: [],
      },
      {
        queryKey: ['graph-market-data', network],
        queryFn: fetchGraphMarkets,
        initialData: [],
      },
      {
        queryKey: ['soft-delisted-markets', network],
        queryFn: async (): Promise<string[]> =>
          await comptroller.getAllSoftDelistedMarkets(),
        initialData: [],
      },
      {
        queryKey: ['oracle-Address', network, comptroller],
        queryFn: async (): Promise<string> => await comptroller.oracle(),
        enabled: !!comptroller,
      },
    ],
  });

  const fetchUsdPrices = useCallback(async (): Promise<
    Array<{ cTokenAddress: string; price: BigNumber }>
  > => {
    if (!allMarkets || !oracleAddress) {
      return [];
    }

    const oracleABI =
      network === Protocol.Ethereum
        ? EthereumPriceOracle__factory.abi
        : PriceOracle__factory.abi;

    const priceList = await multiCalls(
      map(allMarkets, (address) =>
        buildCall(oracleAddress, oracleABI, 'getUnderlyingPrice', [address])
      )
    );
    return map(allMarkets, (market, index) => ({
      cTokenAddress: market,
      price: priceList[index][0],
    }));
  }, [allMarkets, oracleAddress, network, multiCalls, buildCall]);

  const fetchExchangeRates = useCallback(async (): Promise<
    Array<{ cTokenAddress: string; exchangeRate: BigNumber }>
  > => {
    if (!subGraphData) {
      return [];
    }

    const exchangeRates = await multiCalls(
      map(subGraphData, ({ address }) =>
        buildCall(address, CToken__factory.abi, 'exchangeRateStored', [])
      )
    );

    return map(subGraphData, ({ address }, index) => ({
      cTokenAddress: address,
      exchangeRate: exchangeRates[index][0],
    }));
  }, [buildCall, multiCalls, subGraphData]);

  const fetchReserveFactors = useCallback(async (): Promise<
    Array<{ cTokenAddress: string; reserveFactor: BigNumber }>
  > => {
    if (isEmpty(subGraphData)) {
      return [];
    }

    const reserveFactors = await multiCalls(
      map(subGraphData, ({ address }) =>
        buildCall(address, CToken__factory.abi, 'reserveFactorMantissa', [])
      )
    );

    return map(subGraphData, ({ address }, index) => ({
      cTokenAddress: address,
      reserveFactor: reserveFactors[index][0],
    }));
  }, [buildCall, multiCalls, subGraphData]);

  const fetchCollateralFactors = useCallback(async (): Promise<
    Array<{
      cTokenAddress: string;
      collateralFactor: BigNumber;
      version: number;
    }>
  > => {
    if (isEmpty(subGraphData)) {
      return [];
    }

    const markets = await multiCalls(
      map(subGraphData, ({ address }) =>
        buildCall(comptrollerAddress, comptrollerABI, 'markets', [address])
      )
    );

    return map(subGraphData, ({ address }, index) => ({
      cTokenAddress: address,
      collateralFactor: markets[index].collateralFactorMantissa,
      version: markets[index].version,
    }));
  }, [buildCall, comptrollerABI, comptrollerAddress, multiCalls, subGraphData]);

  const fetchBorrowCaps = useCallback(async (): Promise<
    Array<{
      cTokenAddress: string;
      borrowCap: BigNumber;
    }>
  > => {
    if (isEmpty(subGraphData)) {
      return [];
    }

    const results = await multiCalls(
      map(subGraphData, ({ address }) =>
        buildCall(comptrollerAddress, comptrollerABI, 'borrowCaps', [address])
      )
    );

    return map(subGraphData, ({ address }, index) => ({
      cTokenAddress: address,
      borrowCap: results[index][0],
    }));
  }, [buildCall, comptrollerABI, comptrollerAddress, multiCalls, subGraphData]);

  const fetchTotalBorrows = useCallback(async (): Promise<
    Array<{ cTokenAddress: string; totalBorrow: BigNumber }>
  > => {
    if (!subGraphData) {
      return [];
    }

    const totalBorrows = await multiCalls(
      map(subGraphData, ({ address }) =>
        buildCall(address, CToken__factory.abi, 'totalBorrows', [])
      )
    );

    return map(subGraphData, ({ address }, index) => ({
      cTokenAddress: address,
      totalBorrow: totalBorrows[index][0],
    }));
  }, [buildCall, multiCalls, subGraphData]);

  const [
    { data: exchangeRates },
    { data: usdPrices },
    { data: reserveFactors },
    { data: collateralFactors },
    { data: borrowCaps },
    { data: totalBorrows },
  ] = useQueries({
    queries: [
      {
        queryKey: ['exchange-rates', subGraphData],
        queryFn: fetchExchangeRates,
        enabled: !isEmpty(subGraphData),
        initialData: [],
      },
      {
        queryKey: ['usd-prices', allMarkets],
        queryFn: fetchUsdPrices,
        enabled: !!allMarkets && !!oracleAddress,
        initialData: [],
      },
      {
        queryKey: ['reserve-factors', network, subGraphData],
        queryFn: fetchReserveFactors,
        enabled: !isEmpty(subGraphData),
        initialData: [],
      },
      {
        queryKey: ['collateral-factors', network, subGraphData],
        queryFn: fetchCollateralFactors,
        enabled: !isEmpty(subGraphData),
        initialData: [],
      },
      {
        queryKey: ['borrow-caps', network, subGraphData],
        queryFn: fetchBorrowCaps,
        enabled: !isEmpty(subGraphData),
        initialData: [],
      },
      {
        queryKey: ['total-borrows', subGraphData],
        queryFn: fetchTotalBorrows,
        enabled: !isEmpty(subGraphData),
        initialData: [],
      },
    ],
  });

  const fetchCollateralTokens = useCallback(async (): Promise<
    Array<{
      cTokenAddress: string;
      totalCollateralTokens: BigNumber;
    }>
  > => {
    if (isEmpty(subGraphData) || isEmpty(collateralFactors)) {
      return [];
    }

    const filteredSubGraphData: QueryMarket[] = filter(
      subGraphData,
      ({ address }) => {
        const marketData = find(collateralFactors, ({ cTokenAddress }) =>
          isSameAddress(address, cTokenAddress)
        );
        if (!marketData) {
          return false;
        }
        return marketData.version === 1;
      }
    );

    const result = await multiCalls(
      map(filteredSubGraphData, ({ address }) =>
        buildCall(address, CToken__factory.abi, 'totalCollateralTokens', [])
      )
    );

    return map(filteredSubGraphData, ({ address }, index) => ({
      cTokenAddress: address,
      totalCollateralTokens: result[index][0],
    }));
  }, [buildCall, collateralFactors, multiCalls, subGraphData]);

  const fetchCollateralCaps = useCallback(async (): Promise<
    Array<{
      cTokenAddress: string;
      collateralCap: BigNumber;
    }>
  > => {
    if (isEmpty(subGraphData) || isEmpty(collateralFactors)) {
      return [];
    }

    const filteredSubGraphData: QueryMarket[] = filter(
      subGraphData,
      ({ address }) => {
        const marketData = find(collateralFactors, ({ cTokenAddress }) =>
          isSameAddress(address, cTokenAddress)
        );
        if (!marketData) {
          return false;
        }
        return marketData.version === 1;
      }
    );

    const result = await multiCalls(
      map(filteredSubGraphData, ({ address }) =>
        buildCall(address, CToken__factory.abi, 'collateralCap', [])
      )
    );

    return map(filteredSubGraphData, ({ address }, index) => ({
      cTokenAddress: address,
      collateralCap: result[index][0],
    }));
  }, [buildCall, collateralFactors, multiCalls, subGraphData]);

  const [{ data: totalCollateralTokens }, { data: collateralCaps }] =
    useQueries({
      queries: [
        {
          queryKey: [
            'collateral-tokens',
            network,
            subGraphData,
            collateralFactors,
          ],
          queryFn: fetchCollateralTokens,
          enabled: !isEmpty(subGraphData) && !isEmpty(collateralFactors),
          initialData: [],
        },
        {
          queryKey: [
            'collateral-caps',
            network,
            subGraphData,
            collateralFactors,
          ],
          queryFn: fetchCollateralCaps,
          enabled: !isEmpty(subGraphData) && !isEmpty(collateralFactors),
          initialData: [],
        },
      ],
    });

  const formatMarket = useCallback(
    ({
      id,
      address,
      symbol,
      name,
      implementation,
      flashloanPaused,
      underlyingName,
      underlyingSymbol,
      underlyingAddress,
      underlyingDecimals,
      interestRateModelAddress,
      borrowPaused,
      supplyPaused,
      delisted,
    }: QueryMarket): Market => {
      const exchangeRateData = find(exchangeRates, ({ cTokenAddress }) =>
        isSameAddress(cTokenAddress, address)
      );
      const usdPriceData = find(usdPrices, ({ cTokenAddress }) =>
        isSameAddress(cTokenAddress, address)
      );
      const reserveFactorData = find(reserveFactors, ({ cTokenAddress }) =>
        isSameAddress(cTokenAddress, address)
      );

      const collateralFactorData = find(
        collateralFactors,
        ({ cTokenAddress }) => isSameAddress(cTokenAddress, address)
      );

      const collateralTokenData = find(
        totalCollateralTokens,
        ({ cTokenAddress }) => isSameAddress(cTokenAddress, address)
      );

      const collateralCapData = find(collateralCaps, ({ cTokenAddress }) =>
        isSameAddress(cTokenAddress, address)
      );

      const borrowCapData = find(borrowCaps, ({ cTokenAddress }) =>
        isSameAddress(cTokenAddress, address)
      );

      const totalBorrowData = find(totalBorrows, ({ cTokenAddress }) =>
        isSameAddress(cTokenAddress, address)
      );

      const collateralTokens =
        collateralTokenData && collateralTokenData.totalCollateralTokens
          ? new BN(
              utils.formatUnits(
                collateralTokenData.totalCollateralTokens,
                CTokenDecimal
              )
            )
          : undefined;
      const collateralCap = collateralCapData
        ? new BN(
            utils.formatUnits(collateralCapData.collateralCap, CTokenDecimal)
          )
        : undefined;

      const underlyingPrice = usdPriceData
        ? getUnderlyingPriceFromUsdPrice(usdPriceData.price, underlyingDecimals)
        : undefined;

      const underlyingBorrowCap = borrowCapData
        ? utils.formatUnits(borrowCapData.borrowCap, underlyingDecimals)
        : '0';

      const underlyingTotalBorrow = totalBorrowData
        ? utils.formatUnits(totalBorrowData.totalBorrow, underlyingDecimals)
        : '0';

      const borrowUsage = getCollateralUsage(
        new BN(
          utils.formatUnits(
            totalBorrowData?.totalBorrow || '0',
            underlyingDecimals
          )
        ),
        new BN(underlyingBorrowCap)
      );

      return {
        id,
        address,
        symbol,
        name,
        implementation,
        flashloanPaused,
        underlyingName,
        underlyingSymbol,
        underlyingAddress,
        underlyingDecimals,
        underlyingPrice,
        interestRateModelAddress,
        reserveFactor: reserveFactorData
          ? new BN(utils.formatUnits(reserveFactorData.reserveFactor, 18))
          : undefined,
        collateralTokens,
        collateralFactor: collateralFactorData
          ? new BN(utils.formatUnits(collateralFactorData.collateralFactor, 18))
          : undefined,
        collateralCap: {
          cToken: collateralCap?.toString() || '0',
          underlying:
            exchangeRateData && collateralCapData
              ? getUnderlyingFromCToken(
                  collateralCapData.collateralCap,
                  exchangeRateData.exchangeRate,
                  underlyingDecimals
                ).toString()
              : '0',
          usd:
            exchangeRateData && usdPriceData && collateralCapData
              ? getUsdFromUnderlying(
                  collateralCapData.collateralCap,
                  exchangeRateData.exchangeRate,
                  underlyingDecimals,
                  usdPriceData.price
                ).toString()
              : '0',
        },
        collateralUsage: getCollateralUsage(collateralTokens, collateralCap),
        borrowCap: {
          underlying: underlyingBorrowCap,
          usd: new BN(underlyingBorrowCap)
            .multipliedBy(utils.formatUnits(usdPriceData?.price || '0', 18))
            .shiftedBy(underlyingDecimals - 18)
            .toString(),
        },
        totalBorrow: {
          underlying: underlyingTotalBorrow,
          usd: new BN(underlyingTotalBorrow)
            .multipliedBy(utils.formatUnits(usdPriceData?.price || '0', 18))
            .shiftedBy(underlyingDecimals - 18)
            .toString(),
        },
        borrowUsage,
        borrowPaused,
        supplyPaused,
        delisted,
      };
    },
    [
      borrowCaps,
      collateralCaps,
      collateralFactors,
      exchangeRates,
      reserveFactors,
      totalBorrows,
      totalCollateralTokens,
      usdPrices,
    ]
  );

  const formattedMarkets = useMemo<Market[]>(() => {
    if (isEmpty(subGraphData)) {
      return [];
    }

    return map(subGraphData, formatMarket);
  }, [formatMarket, subGraphData]);

  const softDelistedMarkets = useMemo<Market[]>(() => {
    if (isEmpty(softDelistedAddresses) || isEmpty(formattedMarkets)) {
      return [];
    }

    return filter(formattedMarkets, (market) =>
      includes(map(softDelistedAddresses, toLower), toLower(market.address))
    );
  }, [formattedMarkets, softDelistedAddresses]);

  const listedMarkets = useMemo<Market[]>(() => {
    if (!allMarkets || isEmpty(formattedMarkets)) {
      return [];
    }
    return filter(formattedMarkets, (market) =>
      includes(map(allMarkets, toLower), toLower(market.address))
    );
  }, [allMarkets, formattedMarkets]);

  return {
    listed: listedMarkets,
    softDelisted: softDelistedMarkets,
    hardDelisted: [],
    isFetching:
      isAllMarketsFetching || isSubFetching || isSoftDelistedAddressesFetching,
    isSuccess: isSuccess && isSubSuccess,
  };
}

export default useMarkets;
