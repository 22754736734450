import { map } from 'lodash';
import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import {
  VictoryChart,
  VictoryGroup,
  VictoryBar,
  VictoryTooltip,
  VictoryTheme,
  VictoryAxis,
} from 'victory';
import { AlphaData } from 'pages/AlphaHomoraPage';
import { Protocol } from 'types';

interface AlphaChartProps {
  alphaData: AlphaData[];
}

const getTickValue = (value: number) =>
  Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 1,
  }).format(value);

export const AlphaChart = ({ alphaData }: AlphaChartProps): JSX.Element => {
  return (
    <Card>
      <CardHeader title="Borrow Lending Amount" subheader={Protocol.Ethereum} />
      <CardContent>
        <Grid container justifyContent="center">
          {map(
            alphaData,
            ({
              underlyingSymbol,
              underlyingAddress,
              lendingAmount,
              totalBorrow,
            }) => {
              return (
                <Grid key={underlyingAddress} item sm={6} md={3}>
                  <Card>
                    <CardHeader title={underlyingSymbol} />
                    <VictoryChart
                      theme={VictoryTheme.material}
                      domainPadding={80}
                      padding={{ top: 0, left: 60, right: 20, bottom: 30 }}
                      height={150}
                    >
                      <VictoryGroup
                        horizontal
                        labelComponent={<VictoryTooltip />}
                        colorScale={['tomato', 'orange']}
                      >
                        <VictoryBar
                          data={[
                            {
                              x: 'lending',
                              y: lendingAmount.toNumber(),
                              label: lendingAmount.toFormat(2),
                            },
                          ]}
                        />
                        <VictoryBar
                          data={[
                            {
                              x: 'borrow',
                              y: totalBorrow.toNumber(),
                              label: totalBorrow.toFormat(2),
                            },
                          ]}
                        />
                        <VictoryAxis tickValues={['lending', 'borrow']} />
                        <VictoryAxis dependentAxis tickFormat={getTickValue} />
                      </VictoryGroup>
                    </VictoryChart>
                  </Card>
                </Grid>
              );
            }
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AlphaChart;
