/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  IBFFBorrowerCurveLP,
  IBFFBorrowerCurveLPInterface,
} from "../IBFFBorrowerCurveLP";

const _abi = [
  {
    inputs: [
      {
        internalType: "string[]",
        name: "_names",
        type: "string[]",
      },
      {
        internalType: "uint256[]",
        name: "_pids",
        type: "uint256[]",
      },
      {
        internalType: "address[]",
        name: "_underlyingTokens",
        type: "address[]",
      },
      {
        internalType: "address[]",
        name: "_synths",
        type: "address[]",
      },
      {
        internalType: "address[]",
        name: "_cyTokens",
        type: "address[]",
      },
      {
        internalType: "address[]",
        name: "_curveLpTokens",
        type: "address[]",
      },
      {
        internalType: "address[]",
        name: "_curvePools",
        type: "address[]",
      },
      {
        internalType: "address[]",
        name: "_rewardsContracts",
        type: "address[]",
      },
      {
        internalType: "uint256[]",
        name: "_chainlinkUint",
        type: "uint256[]",
      },
      {
        internalType: "bytes32[]",
        name: "_currencyKeys",
        type: "bytes32[]",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_forexKey",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_amount",
        type: "uint256",
      },
    ],
    name: "borrow",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_forexKey",
        type: "uint256",
      },
    ],
    name: "borrowedValueInUsdc",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_forexKey",
        type: "uint256",
      },
    ],
    name: "canRepayBorrowStored",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "claimRewards",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_forexKey",
        type: "uint256",
      },
    ],
    name: "claimableBalance",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_forexKey",
        type: "uint256",
      },
    ],
    name: "claimableProfitInUsdc",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_forexKey",
        type: "uint256",
      },
    ],
    name: "creditLimit",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_forexKey",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_forexAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_minAmountOut",
        type: "uint256",
      },
    ],
    name: "deposit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256[]",
        name: "_keys",
        type: "uint256[]",
      },
      {
        internalType: "address[]",
        name: "_tokensToRug",
        type: "address[]",
      },
    ],
    name: "forceCloseMax",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "forceHarvest",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "forexKey",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "forceRepayBorrow",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "forexKey",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "wethAmount",
        type: "uint256",
      },
    ],
    name: "forceSellWethToForex",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "forexKey",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "minAmountOut",
        type: "uint256",
      },
    ],
    name: "forceWithdrawToForex",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "forexInfo",
    outputs: [
      {
        internalType: "string",
        name: "name",
        type: "string",
      },
      {
        internalType: "uint256",
        name: "pid",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "underlying",
        type: "address",
      },
      {
        internalType: "address",
        name: "synth",
        type: "address",
      },
      {
        internalType: "address",
        name: "cyToken",
        type: "address",
      },
      {
        internalType: "address",
        name: "curveLpToken",
        type: "address",
      },
      {
        internalType: "address",
        name: "curvePool",
        type: "address",
      },
      {
        internalType: "address",
        name: "rewardsContract",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "borrowLimit",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "borrowAmountStored",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "chainlinkUint",
        type: "uint256",
      },
      {
        internalType: "bytes32",
        name: "currencyKey",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "harvest",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_forexKey",
        type: "uint256",
      },
    ],
    name: "holdingsValueInUsdc",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "ironBankMultisig",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "forexKey",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "repayBorrow",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "rewardsContracts",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "forexKey",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "wethAmount",
        type: "uint256",
      },
    ],
    name: "sellWethToForex",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bool",
        name: "_claimRewards",
        type: "bool",
      },
    ],
    name: "setClaimRewards",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_forexKey",
        type: "uint256",
      },
    ],
    name: "stakedBalance",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "sweepWeth",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_forexKey",
        type: "uint256",
      },
    ],
    name: "usdcPerForex",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "forexKey",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "minAmountOut",
        type: "uint256",
      },
    ],
    name: "withdrawToForex",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    stateMutability: "payable",
    type: "receive",
  },
];

export class IBFFBorrowerCurveLP__factory {
  static readonly abi = _abi;
  static createInterface(): IBFFBorrowerCurveLPInterface {
    return new utils.Interface(_abi) as IBFFBorrowerCurveLPInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IBFFBorrowerCurveLP {
    return new Contract(address, _abi, signerOrProvider) as IBFFBorrowerCurveLP;
  }
}
