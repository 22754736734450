export const Colors: string[] = [
  '#ff9800',
  '#607d8b',
  '#795548',
  '#9e9e9e',
  '#8bc34a',
  '#03a9f4',
  '#e91e63',
  '#03a9f4',
];

export default {
  Colors,
};
