import { Typography, Button, Box } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import useUser from 'hooks/useUser';
import UserCard from 'components/UserCard';

const AuthPage = () => {
  const { isLogin, login, logout } = useUser();
  const [searchParams] = useSearchParams();
  const redirectPath = searchParams.get('redirectPath') || '/';

  return (
    <section>
      <Typography variant="h4">User</Typography>
      <UserCard />
      <Box my={2}>
        {!isLogin ? (
          <Button
            variant="contained"
            color="success"
            onClick={() => login(redirectPath)}
          >
            Login
          </Button>
        ) : (
          <Button variant="contained" color="error" onClick={logout}>
            Logout
          </Button>
        )}
      </Box>
    </section>
  );
};

export default AuthPage;
