import {
  Card,
  CardHeader,
  CardContent,
  Stack,
  IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { AddressAlias, Protocol } from 'types';
import SortableTable, { SortedTableColumn } from 'components/SortableTable';
import AddressDisplay from 'components/AddressDisplay';
import { getNetworkName } from 'helpers';

interface AddressColumn extends SortedTableColumn {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render?: (row: AddressAlias) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sorting?: any;
}

interface AddressTableProps {
  loading: boolean;
  addresses: AddressAlias[];
  onAdd: () => void;
  onEdit: (
    id: string,
    oldNetwork: Protocol,
    oldName: string,
    oldAddress: string
  ) => void;
  onDelete: (id: string, network: Protocol, address: string) => void;
}

function AddressTable(props: AddressTableProps): JSX.Element {
  const { loading, addresses, onAdd, onEdit, onDelete } = props;

  const columns: AddressColumn[] = [
    {
      id: 'name',
      label: 'Name',
      align: 'left',
      sortable: true,
      sx: { maxWidth: '250px' },
    },
    {
      id: 'network',
      label: 'Network',
      align: 'left',
      sortable: true,
      render: ({ network }) => getNetworkName(network),
    },
    {
      id: 'address',
      label: 'Address',
      align: 'left',
      sortable: true,
      render: ({ network, address }) => (
        <AddressDisplay
          network={network}
          address={address}
          isShowName={false}
        />
      ),
    },
    {
      id: 'action',
      label: 'Action',
      align: 'left',
      sortable: false,
      render: ({ id, network, name, address }) => {
        return (
          <Stack direction="row">
            <IconButton
              size="small"
              onClick={() => onEdit(id, network, name, address)}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => onDelete(id, network, address)}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  return (
    <Card>
      <CardHeader
        title="Addresses"
        action={
          <IconButton size="small" onClick={onAdd}>
            <AddIcon />
          </IconButton>
        }
      />
      <CardContent>
        <SortableTable
          sx={{ maxHeight: 600 }}
          columns={columns}
          isLoading={loading}
          dataItems={addresses}
          defaultOrderKey="name"
        />
      </CardContent>
    </Card>
  );
}

export default AddressTable;
