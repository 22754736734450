import { useCallback, useContext, useEffect, useState } from 'react';
import {
  collection,
  doc,
  getDocs,
  addDoc,
  setDoc,
  deleteDoc,
} from 'firebase/firestore';
import { map } from 'lodash';
import { FirebaseContext } from 'providers/FirebaseProvider';
import { AddressAlias, Protocol } from 'types';

const useAddress = () => {
  const { firebaseDB } = useContext(FirebaseContext);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [addresses, setAddresses] = useState<AddressAlias[]>([]);

  const updateAddress = async (
    id: string,
    network: Protocol,
    name: string,
    address: string
  ) => {
    try {
      setIsFetching(true);
      if (!id || !network || !address || !name) {
        throw Error('input value error');
      }
      await setDoc(
        doc(firebaseDB, 'addresses', id),
        { network, address, name },
        { merge: true }
      );
      setIsFetching(false);
      fetchAddresses();
    } catch (error) {
      console.error('updateAddress', error);
      setIsFetching(false);
    }
  };

  const deleteAddress = async (id: string) => {
    try {
      setIsFetching(true);
      if (!id) {
        throw Error('input value error');
      }
      await deleteDoc(doc(firebaseDB, 'addresses', id));
      setIsFetching(false);
      fetchAddresses();
    } catch (error) {
      console.error('deleteAddress', error);
      setIsFetching(false);
    }
  };

  const createAddress = async (
    network: Protocol,
    name: string,
    address: string
  ) => {
    try {
      setIsFetching(true);
      if (!name || !address) {
        throw Error('input value error');
      }
      await addDoc(collection(firebaseDB, 'addresses'), {
        network,
        name,
        address,
      });

      setIsFetching(false);
      fetchAddresses();
    } catch (error) {
      console.error('createAddress', error);
      setIsFetching(false);
    }
  };

  const fetchAddresses = useCallback(async () => {
    try {
      if (!firebaseDB) {
        throw Error('no db error');
      }
      setIsFetching(true);
      const addressesRef = collection(firebaseDB, 'addresses');
      const addressesSnap = await getDocs(addressesRef);
      const resp = map(addressesSnap.docs, (doc) => {
        const aliasData = doc.data();
        return {
          id: doc.id,
          ...aliasData,
        } as AddressAlias;
      });

      setAddresses(resp);
      setIsFetching(false);
    } catch (error) {
      console.error('fetchAddresses', error);
      setAddresses([]);
      setIsFetching(false);
    }
  }, [firebaseDB]);

  useEffect(() => {
    fetchAddresses();
  }, [fetchAddresses]);

  return {
    addressAlias: addresses,
    createAddress,
    deleteAddress,
    updateAddress,
    isFetching,
  };
};

export default useAddress;
