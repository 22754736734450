import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import CssBaseline from '@mui/material/CssBaseline';
import { theme } from 'theme';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { FirebaseProvider } from 'providers/FirebaseProvider';
import { ThemeProvider } from '@mui/material/styles';
import { DialogProvider } from 'providers/DialogProvider';
import { Web3Provider } from 'providers/Web3Provider';
import { SessionProvider } from 'providers/SessionProvider';

const queryClient = new QueryClient();

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <SessionProvider>
      <Web3Provider>
        <QueryClientProvider client={queryClient}>
          <FirebaseProvider>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <DialogProvider>
                <App />
              </DialogProvider>
            </ThemeProvider>
          </FirebaseProvider>
          <ReactQueryDevtools initialIsOpen={true} />
        </QueryClientProvider>
      </Web3Provider>
    </SessionProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
