import { Card, CardHeader, CardContent } from '@mui/material';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { map, minBy, flatMapDeep } from 'lodash';
import { format, getUnixTime, fromUnixTime } from 'date-fns';
import { useCreditLimitUsages } from 'hooks/useCreditLimitUsages';
import { Colors } from './constants';
import { Protocol } from 'types';
import { AddressDisplay } from 'components/AddressDisplay';
import { useMemo } from 'react';

interface AccountCreditLimitChartProps {
  network: Protocol;
  account: string;
  markets: string[];
}

export const AccountCreditLimitChart = ({
  network,
  account,
  markets,
}: AccountCreditLimitChartProps): JSX.Element => {
  const { creditUsages: data } = useCreditLimitUsages(
    network,
    account,
    markets
  );

  const minX = useMemo<number>(() => {
    const min = minBy(
      flatMapDeep(data, (usageData) => usageData.data),
      ({ date }) => getUnixTime(date)
    );
    return min ? getUnixTime(min.date) : 0;
  }, [data]);

  return (
    <Card>
      <CardHeader
        title={<AddressDisplay network={network} address={account} />}
        subheader={network}
      />
      <CardContent>
        <ResponsiveContainer height={300}>
          <LineChart>
            <CartesianGrid strokeDasharray="3 3" />
            <YAxis
              ticks={[0, 20, 40, 60, 80, 100, 120]}
              domain={[0, 120]}
              unit="%"
            />
            <Tooltip
              labelFormatter={(label: number) =>
                format(fromUnixTime(label), 'yyyy/MM/dd HH:mm a') || label
              }
              formatter={(value, name) => [`${value}%`, name]}
            />
            <Legend />
            <XAxis
              allowDuplicatedCategory={false}
              dataKey="date"
              scale="time"
              domain={[minX, getUnixTime(new Date())]}
              tickFormatter={(date: number) =>
                format(fromUnixTime(date), 'MM/dd')
              }
            />
            {map(data, ({ market, data, symbol }, index) => (
              <Line
                id={market}
                key={market}
                dataKey="usage"
                strokeWidth={2}
                data={map(data, ({ date, usage, ...restData }) => ({
                  ...restData,
                  date: getUnixTime(date),
                  usage: usage >= 120 ? 120 : usage,
                }))}
                name={symbol}
                stroke={Colors[index]}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default AccountCreditLimitChart;
