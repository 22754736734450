import { useState, ReactNode } from 'react';
import { map, get } from 'lodash';
import { TableRow, TableCell, IconButton, Collapse } from '@mui/material';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@mui/icons-material';
import { SortedTableColumn } from './index';

interface ExpandableTableRowProps<T> {
  expandComponent: ReactNode;
  rowData: T;
  columns: SortedTableColumn[];
}

export const ExpandableTableRow = <T,>({
  columns,
  rowData,
  expandComponent,
}: ExpandableTableRowProps<T>) => {
  const [isExpand, setIsExpand] = useState<boolean>(false);

  return (
    <>
      <TableRow selected={isExpand}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setIsExpand(!isExpand)}
          >
            {isExpand ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {map(columns, (column) => (
          <TableCell key={column.id} align={column.align}>
            {column.render ? column.render(rowData) : get(rowData, column.id)}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell sx={{ borderBottom: 0 }} colSpan={columns.length + 1}>
          <Collapse in={isExpand} timeout="auto" unmountOnExit>
            {expandComponent || undefined}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ExpandableTableRow;
