import { useContext, useCallback, useMemo } from 'react';
import { signInWithPopup, GoogleAuthProvider, signOut } from 'firebase/auth';
import { SessionContext } from 'providers/SessionProvider';
import { FirebaseContext } from 'providers/FirebaseProvider';
import { useNavigate } from 'react-router-dom';

const useUser = () => {
  const { auth } = useContext(FirebaseContext);
  const { session, updateSession } = useContext(SessionContext);

  const provider = useMemo(() => new GoogleAuthProvider(), []);
  provider.setCustomParameters({
    prompt: 'select_account',
    hd: 'ib.xyz',
  });
  const navigate = useNavigate();

  const logout = useCallback(() => {
    signOut(auth).then(() => {
      navigate('/');
    });
  }, [auth, navigate]);

  const login = useCallback(
    (redirectPath: string) => {
      signInWithPopup(auth, provider).then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        if (!credential) {
          throw new Error('no credential');
        }
        // const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        // ...
        window.localStorage.setItem('refreshToken', user.refreshToken);
        updateSession({
          isAuthenticated: true,
          user: {
            name: user.displayName || '',
            email: user.email || '',
            photoURL: user.photoURL || '',
          },
        });
        navigate(redirectPath || '/');
      });
    },
    [auth, navigate, provider, updateSession]
  );

  return {
    isLogin: session.isAuthenticated || false,
    login,
    logout,
    user: session.user,
  };
};

export default useUser;
