import { createContext, ReactNode, useState, useRef, useContext } from 'react';
import { map } from 'lodash';
import { Dialog, DialogTitle } from '@mui/material';

interface DialogContextProps {
  children: ReactNode;
}

interface DialogParams {
  title?: string;
  children: ReactNode;
  open: boolean;
  onClose?: () => void;
}

type DialogOption = Omit<DialogParams, 'open'>;
type ProviderContext = readonly [(option: DialogOption) => void, () => void];

// eslint-disable-next-line @typescript-eslint/no-empty-function
const EMPTY_FUNC = () => {};

export const DialogContext = createContext<ProviderContext>([
  EMPTY_FUNC,
  EMPTY_FUNC,
]);

function DialogContainer(props: DialogParams) {
  const { title, children, open, onClose, ...restProps } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      {...restProps}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      {children}
    </Dialog>
  );
}

export const DialogProvider = ({ children }: DialogContextProps) => {
  const [dialogs, setDialogs] = useState<DialogParams[]>([]);

  const createDialog = (option: DialogOption) => {
    const dialog = { ...option, open: true };

    setDialogs((dialogs) => [...dialogs, dialog]);
  };

  const closeDialog = () => {
    setDialogs((dialogs) => {
      const latestDialog = dialogs.pop();

      if (!latestDialog) {
        return dialogs;
      }

      if (latestDialog.onClose) {
        latestDialog.onClose();
      }

      return [...dialogs].concat({ ...latestDialog, open: false });
    });
  };

  const contextValue = useRef([createDialog, closeDialog] as const);

  return (
    <DialogContext.Provider value={contextValue.current}>
      {children}
      {map(dialogs, (dialog, i) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { onClose, ...dialogParams } = dialog;

        return (
          <DialogContainer key={i} onClose={closeDialog} {...dialogParams} />
        );
      })}
    </DialogContext.Provider>
  );
};

export const useDialog = () => useContext(DialogContext);
export default DialogProvider;
