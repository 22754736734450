import { useMemo } from 'react';
import { BigNumber } from 'ethers';
import { useQuery } from '@tanstack/react-query';
import { map, reduce, find, isEmpty } from 'lodash';
import { useMulticall } from 'hooks/useMultiCall';
import { useMarkets } from 'hooks/useMarkets';
import { CErc20__factory } from 'contracts/types/factories/CErc20__factory';
import { Protocol } from 'types';
import { isSameAddress } from 'helpers';

export type BorrowerBalance = {
  address: string;
  underlyingSymbol: string;
  underlyingName: string;
  underlyingDecimals: number;
  balance: BigNumber | null;
};

export function useBalances(network: Protocol, borrowerAddress: string) {
  const { multiCalls, buildCall } = useMulticall(network);
  const { listed } = useMarkets(network);

  const {
    data: balances,
    isLoading,
    isFetching,
  } = useQuery(
    ['market-balances', network, borrowerAddress],
    async (): Promise<Array<{ address: string; balance: BigNumber }>> => {
      if (!borrowerAddress || !listed) {
        return [];
      }

      const calls = map(listed, ({ address }) => {
        return buildCall(address, CErc20__factory.abi, 'borrowBalanceStored', [
          borrowerAddress,
        ]);
      });

      const balances = await multiCalls(calls);

      if (!balances) {
        return [];
      }

      return map(listed, ({ address }, index) => {
        return {
          address,
          balance: balances[index][0],
        };
      });
    },
    { enabled: !isEmpty(listed) && !!borrowerAddress }
  );

  const marketBalances = useMemo<BorrowerBalance[]>(() => {
    if (!listed || !balances) {
      return [];
    }

    return reduce(
      listed,
      (
        acc: BorrowerBalance[],
        { address, underlyingName, underlyingSymbol, underlyingDecimals }
      ) => {
        const balanceData = find(balances, (balance) =>
          isSameAddress(balance.address, address)
        );

        if (!balanceData || balanceData.balance.isZero()) {
          return acc;
        }

        return acc.concat({
          address,
          underlyingSymbol,
          underlyingName,
          underlyingDecimals,
          balance: balanceData ? balanceData.balance : null,
        });
      },
      []
    );
  }, [balances, listed]);

  return {
    marketBalances,
    isLoading,
    isFetching,
  };
}

export default useBalances;
