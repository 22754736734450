import { createContext, ReactNode } from 'react';
import { ethers } from 'ethers';
import { Provider } from '@ethersproject/abstract-provider';
import { Networks } from 'configurations';
import { Protocol } from 'types';

interface Web3ContextProps {
  children: ReactNode;
}

interface Web3Context {
  providers: {
    [key: string]: Provider;
  };
}

const defaultWeb3Context = {
  providers: {
    ethereum: new ethers.providers.JsonRpcProvider(
      Networks[Protocol.Ethereum].providerURL
    ),
    fantom: new ethers.providers.JsonRpcProvider(
      Networks[Protocol.Fantom].providerURL
    ),
    avalanche: new ethers.providers.JsonRpcProvider(
      Networks[Protocol.Avalanche].providerURL
    ),
  },
};

export const Web3Context = createContext<Web3Context>(defaultWeb3Context);

export const Web3Provider = ({ children }: Web3ContextProps) => {
  return (
    <Web3Context.Provider value={defaultWeb3Context}>
      {children}
    </Web3Context.Provider>
  );
};

export default Web3Provider;
